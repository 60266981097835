import React, { useState, useEffect } from 'react';
import { Input, InputBorderless } from '../atoms/Input';
import { CallToActionLinkButton, LinkButton } from '../atoms/Button';
import { SearchIcon } from '../atoms/SearchIcon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from './Grid';
import { StorySongDropdown } from './StorySongDropdown';
import { ArchiveDropdown } from './ArchiveDropdown';
import { FormDropdown } from './FormDropdown';
import { LanguageDropdown } from './LanguageDropdown';
import {
  DropDownContainer,
  SearchContainer,
  SearchContainerTransparent,
} from './SearchBox.style';
import { useQueryParam, StringParam } from 'use-query-params';
import FormControl from '@material-ui/core/FormControl';
import { storyFields } from '../utils/esFieldNames';

export const SearchBoxInner: React.FC<{
  onSearch: (val: string) => void;
  inputName: string;
  placeholder?: string;
  value?: string;
}> = ({ onSearch, inputName, placeholder = 'Keywords' }) => {
  const [keywords, setKeywords] = useQueryParam('keywords', StringParam);
  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState(keywords || '');
  const { t } = useTranslation();

  useEffect(() => {
    if (keywords) {
      setSearchValue(keywords as string);
    } else {
      setSearchValue('');
    }
  }, [keywords]);

  const handleSubmit = () => {
    onSearch(searchValue);
    setKeywords(searchValue);
  };

  const handleKeyPress = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSubmit();
    }
  };

  return (
    <SearchContainer $isFocus={isFocus}>
      <InputBorderless
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        name={inputName}
        type="text"
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
        placeholder={t(placeholder)}
        onKeyDown={handleKeyPress}
      />
      <LinkButton onClick={handleSubmit}>
        <SearchIcon />
      </LinkButton>
    </SearchContainer>
  );
};

export const SearchBoxSmall: React.FC<{
  onSearch: (val: string) => void;
  placeholder?: string;
  value?: string;
}> = ({ onSearch, placeholder = 'Keywords' }) => {
  const [keywords, setKeywords] = useQueryParam('keywords', StringParam);
  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState(keywords || '');
  const { t } = useTranslation();

  useEffect(() => {
    if (keywords) {
      setSearchValue(keywords as string);
    } else {
      setSearchValue('');
    }
  }, [keywords]);

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    onSearch(searchValue);
    setKeywords(searchValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <SearchContainer $isFocus={isFocus}>
        <InputBorderless
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          type="text"
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
          placeholder={t(placeholder)}
        />
        <LinkButton>
          <SearchIcon />
        </LinkButton>
      </SearchContainer>
    </form>
  );
};

export const SearchBoxLarge: React.FC<{
  placeholder?: string;
  value?: string;
}> = ({ placeholder = 'Keywords', value = '' }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState(value || '');
  const [archive, setArchive] = useState('');
  const [language, setLanguage] = useState<Language>('');
  const [laytype, setLaytype] = useState('');
  const [form_subform, setForm] = useState('');
  const [searchURL, setSearchURL] = useState('/search?');
  const { t } = useTranslation();

  useEffect(() => {
    // all home page facets are StoryDocument facets; if any are selected, set the index to documents
    if ([archive, language, laytype, form_subform].some(val => val)) {
      setSearchURL(
        `/search?index=documents&keywords=${searchValue}&${storyFields.archive.name}=${archive}&${storyFields.language.name}=${language}&${storyFields.laytype.name}=${laytype}&${storyFields.form.name}=${form_subform}`
      );
    } else // otherwise search all indices
      setSearchURL(`/search?keywords=${searchValue}`);
  }, [searchValue, archive, language, laytype, form_subform]);

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
      }}
    >
      <SearchContainerTransparent $isFocus={isFocus}>
        <Input
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          type="text"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t(placeholder)}
        />
        <CallToActionLinkButton>
          <Link
            to={searchURL}
          >
            {`${t('Search')}`}
          </Link>
        </CallToActionLinkButton>
      </SearchContainerTransparent>
      <Grid>
        <DropDownContainer>
          <FormControl fullWidth={true} margin={'dense'}>
            <LanguageDropdown
              selected={language}
              onChange={selectedLanguage => setLanguage(selectedLanguage)}
            />
          </FormControl>
          &nbsp;
          <FormControl fullWidth={true} margin={'dense'}>
            <StorySongDropdown
              selected={laytype}
              onChange={selectedStorySong => setLaytype(selectedStorySong)}
            />
          </FormControl>
          &nbsp;
          <FormControl fullWidth={true} margin={'dense'}>
            <FormDropdown
              selected={form_subform}
              onChange={selectedForm => setForm(selectedForm)}
            />
          </FormControl>
          &nbsp;
          <FormControl fullWidth={true} margin={'dense'}>
            <ArchiveDropdown
              selected={archive}
              onChange={selectedArchive => setArchive(selectedArchive)}
            />
          </FormControl>
        </DropDownContainer>
      </Grid>
    </form>
  );
};
