export const REL_TO = "Relationship to";
export const COLLECTOR = "Collector";
export const INTERVIEWEE = "Interviewee";
export const SOURCE = "Interviewee's Source";

const intervieweeRoles = [
  "Interviewee",
  "Interviewee 1",
  "Interviewee 2",
  "Interviewee 3",
  "Interviewee 4",
]

const sourceRoles = [
  "Interviewee's Source",
  "Interviewee 1's Source 1",
  "Interviewee 1's Source 2",
  "Interviewee 1's Source 3",
  "Interviewee 1's Source 4",
  "Interviewee 2's Source",
  "Interviewee 3's Source",
  "Interviewee 4's Source",
]

export function isSourceOrInterviewee(label: string) {
  const source_and_interviewee_roles = intervieweeRoles.concat(sourceRoles);
  return source_and_interviewee_roles.includes(label);
}

// Get the relationship info
export function getRelTo(label: string)  {
  if (intervieweeRoles.includes(label)) return `${REL_TO} ${COLLECTOR}`
  if (sourceRoles.includes(label)) return `${REL_TO} ${INTERVIEWEE}`
  return ''
}