// Get value from an object, with a specified key and language suffix
export function getLocaleKeyVal(
    object: any, 
    key_prefix: string,
    language: Language
 ): string {
   const key = `${key_prefix}_${language}`;
   const defaultkey = `${key_prefix}_en`;
   if (object && object[key]) return object[key as keyof any] as string;
   return object && object[defaultkey] ? object[defaultkey as keyof any] as string : "";
 };