import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PersonDisplayCardContainerLink = styled(Link)`
  padding: 1rem 1.375rem;
  min-height: 120px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: baseline;
  text-decoration: none;
  margin: 1rem 0rem;
`;
