import styled from 'styled-components';

export const BreadCrumbs = styled.div`
   {
    font-weight: normal;
    color: #2c80e8;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      font-size: 1rem;
      padding: 0.25rem;
      color: #2c80e8;
      text-decoration: none;
    }
  }
`;

export const Truncate = styled.div`
  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;   
  }
`;


