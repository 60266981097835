import React from 'react';
import { Popup } from 'react-leaflet';
import { usePlace } from '../../hooks/usePlace';
import { PlaceInfo } from './PlaceInfo';

export const MapInfoPopup: React.FC<{ placeId: Place['id'] }> = ({
  placeId,
}) => {
  const { data, refetch } = usePlace(placeId);

  return <Popup onOpen={refetch}>{data && <PlaceInfo place={data} />}</Popup>;
};
