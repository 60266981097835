import i18n from 'i18next';
import { getLocaleKeyVal } from './getLocaleKeyVal';
import { PlaceType } from '../hooks/useSearch';


export function getPlaceNamePriority(
  place: Place,
  language: Language
): string[] {
  const placeNames = [
    getLocaleKeyVal(place.town_village, 'town_village_name', language),
    getLocaleKeyVal(place.parish, 'parish_name', language),
    getLocaleKeyVal(place.barony_district, 'barony_district_name', language),
    getLocaleKeyVal(place.county, 'county_name', language),
  ].filter(name => name);
  return (placeNames.length > 0) ? placeNames : ([i18n.t('Unnamed place')]);
}


export function getESPlaceNamePriority(
  place: PlaceType,
  language: Language
): string[] {
  const placeNames = [
    getLocaleKeyVal(place.town_village, 'town_village_name', language),
    getLocaleKeyVal(place.parish, 'parish_name', language),
    getLocaleKeyVal(place.barony_district, 'barony_district_name', language),
    getLocaleKeyVal(place.county, 'county_name', language),
  ].filter(name => name);
  return (placeNames.length > 0) ? placeNames : ([i18n.t('Unnamed place')]);
}


export function getPlaceNames(
  place: PlaceType,
  language: Language
): string[] {
  const placeNames = [
    getLocaleKeyVal(place.town_village, 'town_village_name', language),
    getLocaleKeyVal(place.parish, 'parish_name', language),
    getLocaleKeyVal(place.barony_district, 'barony_district_name', language),
    getLocaleKeyVal(place.county, 'county_name', language),
  ].filter(name => name);
  return (placeNames.length > 0) ? placeNames : (['']);
}
