import React from 'react';
import styled from 'styled-components';
import { HomePageSearch } from '../components/HomePageSearch';
import {
  ThirdWidthDisplayCardContainer,
  DisplayCardContainer,
  FullWidthDisplayCardContainer,
  DisplayCardTitle,
  DisplayCardSubtitle,
} from '../components/DisplayCard';
import { Grid } from '../components/Grid';
import { LinkButton } from '../atoms/Button';
import { TitleText } from '../atoms/TitleText';
import { IntroductionText } from '../atoms/IntroductionText';
import { Link } from 'react-router-dom';
import { PageContainer } from '../atoms/PageContainer';
import { Trans, useTranslation } from 'react-i18next';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

const HomePageParagraph = styled.p`
  font-size: 1rem;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 24px;
`;

const HomeFeaturedImage = styled.img.attrs(props => ({
  src:props.src,
  alt:props.alt
}))`
  {
    float: left;
    width: 400px;
    padding-right: 20px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const Home: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <HomePageSearch />
      <PageContainer>
        <Grid>
          <IntroductionText>
            <a href="https://fionnfolklore.org/#/item/2252">
              <HomeFeaturedImage src='user_files/Images/HomePageImage.jpg' alt='Mar Rinneadh Rí de Fhionn Mhac Cúmhail' />
            </a>
            <HomePageParagraph> 
              <Trans i18nKey="multiline" t = {t}> {t('Introductory Text')}</Trans>
            </HomePageParagraph>
          </IntroductionText>
        </Grid>
        <Grid>
          <ThirdWidthDisplayCardContainer>
            <DisplayCardTitle>{t('Navigating the Database')}</DisplayCardTitle>
            <DisplayCardSubtitle>
              {t('Navigating the Database HomeText')}
            </DisplayCardSubtitle>
              <LinkButton style={{width: '100%', padding: '0rem'}}>
                <Link to="/Navigating-the-Database">{`${t('More')}`}</Link>
              </LinkButton>
          </ThirdWidthDisplayCardContainer>
          <ThirdWidthDisplayCardContainer>
            <DisplayCardTitle>{t('Glossary')}</DisplayCardTitle>
            <DisplayCardSubtitle>
              {t('Glossary HomeText')}
            </DisplayCardSubtitle>
            <LinkButton style={{width: '100%', padding: '0rem'}}>
                <Link to="/Glossary">{`${t('More')}`}</Link>
              </LinkButton>
          </ThirdWidthDisplayCardContainer>
          <ThirdWidthDisplayCardContainer>
            <DisplayCardTitle>{t('Characters')}</DisplayCardTitle>
            <DisplayCardSubtitle>
              {t('Characters HomeText')}
            </DisplayCardSubtitle>
            <LinkButton style={{width: '100%', padding: '0rem'}}>
                <Link to="/Characters">{`${t('More')}`}</Link>
              </LinkButton>
          </ThirdWidthDisplayCardContainer>
        </Grid>
      </PageContainer>
    </>
  );
};
