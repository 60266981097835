// Takes a url from the api(e.g. for a Place or Person`) 
// and uses it to create a Link to the frontend Route to the relevant asset.
import React from 'react';
import { Link } from 'react-router-dom';

export type ResourceLinkProps = {
    route: string,
    id: number|string,
    linkText: string
}

export function ResourceLink(props: ResourceLinkProps)  {
    return (
        <Link to={`${props.route}${props.id}`}>
          {props.linkText}
        </Link>
    )
}