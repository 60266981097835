import styled, { css } from 'styled-components';
import { Button } from '../../atoms/Button';

export const List = styled.ul`
  list-style: none;
  margin: 1rem 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.2em;
  margin-bottom: 2em;
`;

type InitialLinkProps = {
  selected: boolean;
};

const Selected = css`
  background-color: #7baaff;
  border-color: #7baaff;
  pointer-events: none;
`;

export const InitialLink = styled(Button)<InitialLinkProps>`
  ${props => props.selected && Selected}
  user-select: none;
`;
