import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

type PaginatedCollectionItems = {
  pagination: {
    page: number,
    totalPages: number,
    totalResults: number
  },
  results: Item[]
}

export function useCollectionItems(id: string, page: number = 1) {
  const base = useAPIBase();
  return useQuery<PaginatedCollectionItems>(['items', {id, page}], async () => {
    return fetch(`${base}/collection_items/${id}/?page=${page}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data;
      });
  });
}
