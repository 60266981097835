import React from 'react';
import i18n from 'i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '30%',
      margin: '0.25rem 0',
    },
    smallFormControl: {
      minWidth: '8rem',
      color: 'white',
      '& svg': {
        color: 'white',
      },
      '& .MuiInput-underline::before': {
        borderBottom: 'none',
      },
      '& ..MuiFormControl-root': {
        borderBottom: '0.5px solid white',
      },
    },
  })
);
type DropdownProps<T> = {
  options: Array<{ name: string; value: string }>;
  placeholder?: T | null;
  onChange: (val: T) => void;
  selected?: T;
  varient?: 'outlined' | 'standard' | 'filled' | undefined;
  allowNoneOption?: boolean;
  fixedWidth?: number;
};

export const Dropdown = <T extends string>({
  options,
  placeholder,
  onChange,
  selected,
  varient,
  allowNoneOption,
  fixedWidth
}: DropdownProps<T>) => {
  const classes = useStyles();
  return (
    <FormControl
      size="small"
      variant={varient}
      className={varient ? classes.formControl : classes.smallFormControl}
    >
      <InputLabel
        className={varient ? classes.formControl : classes.smallFormControl}
      >
        {placeholder ? placeholder : null}
      </InputLabel>
      <Select
        onChange={(ev: any) => onChange(ev && ev.target && ev.target.value ? ev.target.value : '')}
        label={placeholder}
        defaultValue=""
        value={selected || ''}
        className={varient ? classes.formControl : classes.smallFormControl}
      >
        {allowNoneOption && (
          <MenuItem key="none_selected" value="">
            <em>{i18n.t('None selected')}</em>
          </MenuItem>
        )}
        {options.map((option, index) => {
            return (
              <MenuItem 
                key={option.value}
                value={option.value}
                style={fixedWidth ? {width: `${fixedWidth}px`, whiteSpace: 'unset'} : {}}
              >{option.name}
              </MenuItem>);
          })
        }
      </Select>
    </FormControl>
  );
};
