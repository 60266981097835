// components/DynamicPage.tsx
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Collapsible } from '../components/Collapsible';
import { RichText } from '../blocks/RichText';

interface CardProps {
  card: {
    title: string;
    collapsible: boolean;
    text: string;
    image_block: {
      image: string;
      alt_text: string;
      subtitle?: string;
      image_position: 'left' | 'right' | 'top';
    } | null;
  };
}

interface CardGroupProps {
  cardGroup: {
    collapsible: boolean;
    full_width: boolean;
    header: string;
    cards: CardProps['card'][];
  };
}

const FullWidthCardContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;

const CollapsibleContainer = styled.div`
  width: 100%;
  background-color: #edf4fd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left:1rem;
  border: solid #eeeeee 1px;
  margin-top: 0.5rem;
`;

const CardContentWrapper = styled.article<{ flexDirection?: 'row' | 'column' | 'row-reverse' }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: center;
  margin: 0;
  padding: 0;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;


const CardGroupTitle = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 20px;
`;

const CardGroupContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  margin: 0 1rem;
  padding: 0;
`;

const CardImage = styled.img`
  max-height: 400px;
  max-width: fit-content;
  width: 100%;
  height: auto;
  margin: 1px auto;
  border-radius: 5px;
`;

const ImageSubtitle = styled.figcaption`
  width: 100%;
  font-size: 0.8em;
  color: #666;
  text-align: center;
`;


const CardContent: React.FC<CardProps> = ({ card }) => {
  const flexDirection = card.image_block 
  ? card.image_block.image_position === 'left' 
    ? 'row' 
    : card.image_block.image_position === 'top' 
    ? 'column' 
    : 'row-reverse'
  : 'column';

  return (
    <CardContentWrapper flexDirection={flexDirection}>
      <CardSection>
        {card.image_block && <CardImage src={card.image_block.image} alt={card.image_block.alt_text} />}
        {card.image_block && <ImageSubtitle>{card.image_block.subtitle}</ImageSubtitle>}
      </CardSection>
      <CardSection>
        {!card.collapsible && <CardTitle>{card.title}</CardTitle>}
        <RichText>
          <div dangerouslySetInnerHTML={{ __html: card.text }} />
        </RichText>
      </CardSection>
    </CardContentWrapper>
  );
};

const SingleCard: React.FC<CardProps> = ({ card }) => (
  <FullWidthCardContainer>
    <CollapsibleContainer>
      {card.collapsible ? (
        <Collapsible label={card.title} bold={true}>
          <CardContent card={card} />
        </Collapsible>
      ) : (
        <CardContent card={card} />
      )}
    </CollapsibleContainer>
  </FullWidthCardContainer>
);

const CardGroup: React.FC<CardGroupProps> = ({ cardGroup }) => {
  const cardGroupContent = (
    <>
      {!cardGroup.collapsible && <CardGroupTitle>{cardGroup.header}</CardGroupTitle>}
      <CardGroupContainer>
        {cardGroup.cards.map((card, index) => (
          <SingleCard key={index} card={card} />
        ))}
      </CardGroupContainer>
    </>
  );

  return cardGroup.collapsible ? (
    <Collapsible label={<CardGroupTitle>{cardGroup.header}</CardGroupTitle>} bold={true}>
      {cardGroupContent}
    </Collapsible>
  ) : (
    cardGroupContent
  );
};

export { SingleCard, CardGroup };
