import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESLayType } from '../hooks/useSearch';


interface LayTypeCardProps {
  result: ESLayType;
}

export const LayTypeCard: React.FC<LayTypeCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function getTitleForLocale(laytype: ESLayType): string {
    if (locale === 'ga') {
      return laytype.title_ga;
    } else if (locale === 'gd') {
      return laytype.title_gd;
    }
    return laytype.title_en;
  }

  function getAltTitlesForLocale(laytype: ESLayType): string[] {
    let titles = [];
    for (const altTitle of laytype.alternative_titles) {
      if (locale === 'ga') {
        titles.push(altTitle.alt_title_ga);
      } else if (locale === 'gd') {
        titles.push(altTitle.alt_title_gd);
      } else {
        titles.push(altTitle.alt_title_en);
      }
    }
    return titles;
  }

  

  return (
    <DisplayCardContainerLinkResults to={`/lays/${result.slug}`} target={"_blank"} rel={"noopener noreferrer"} backgroundColor='#eefffd'>
      <SongStoryTitleContainer>
        <DisplayCardTitle>{getTitleForLocale(result)}</DisplayCardTitle>
        <DisplayCardIcon>
        Lay
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" height={'1.5rem'}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
        </svg>
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>

            {result.alternative_titles.length > 0 && (
                <TileDetail label={t('Alternative Titles')}>
                  {getAltTitlesForLocale(result).map((title, index) => (
                    <div key={index}>{title}</div>
                  ))}
                </TileDetail>
            )}

            {result.international_tale_type && (
              <TileDetail label={t('International Tale Type')}>
                {result.international_tale_type}
              </TileDetail>
            )}

            {result.documents_count && (
              <TileDetail label={t('Associated Documents')}>
                {result.documents_count}
              </TileDetail>
            )}

        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
