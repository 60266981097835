import styled from 'styled-components';


const RichText = styled.div`
  font-size: 1rem;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 24px;
  display: block;
`;

export { RichText };