import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function usePlace(placeId: Place['id']) {
  const base = useAPIBase();
  return useQuery<Place>(
    ['place', placeId],
    async () => {
      return fetch(`${base}/place/${placeId}/`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return null;
        })
        .then(data => {
          return data;
        });
    },
    { enabled: false }
  );
}
