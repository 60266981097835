import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAPIBase } from '../../hooks/use-api-base';
import { useFetch } from '../../hooks/use-fetch-data';
import { useLocale } from '../../hooks/use-locale';
import { LeafletMap } from '../../components/Map';
import { ResourceLink } from '../../components/ResourceLink';
import { GridColumn } from '../../components/Grid';
import { PageContainer } from '../../atoms/PageContainer';
import { BreadCrumbs } from '../../atoms/BreadCrumbs';
import { Loading } from '../../atoms/loading';
import {
  GroupedData,
  ItemDetailsContainer,
  ItemPage,
  KeyValuePair,
  Label,
  Value,
} from './place.style';
import { getPlaceNamePriority } from '../../utils/getPlaceNamePriority';
import {
  Title, TitleSpacer
} from '../../atoms/StyledShared';

const PlaceDetail: React.FC<{ label: string }> = ({ children, label }) => (
  <GroupedData>
    <KeyValuePair>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </KeyValuePair>
  </GroupedData>
);

const linkToPerson = (person: Person, t: any) => (
  <>
    <ResourceLink
      id={person.id}
      route={`/people/`}
      linkText={
        person.canonical_name
          ? person.canonical_name
          : t("Unknown Person Name")
      }
    />
    <br />
  </>
);

export const Place: React.FC = () => {
  const { pathname } = useLocation();
  const base = useAPIBase();
  const [place, setPlace] = useState<Place>();
  const language = useLocale();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPlaceData = async () => {
      const data = await useFetch(
        `${base}/place/${pathname.split('/').pop()}/`
      );
      if (data) {
        setPlace(data);
      }
    };
    fetchPlaceData();
  }, [base, pathname]);

  if (place) {
    const prioritisedPlaceNames = getPlaceNamePriority(place, language);
    return (
      <PageContainer>
        <BreadCrumbs>
          <Link to="/">{t('Home')}</Link>
          {'>'}
          <Link to="/places">{t('Places')}</Link>
          {'>'}
          <Link to="/places">{prioritisedPlaceNames[0]}</Link>
        </BreadCrumbs>
        <TitleSpacer>
          <Title>{prioritisedPlaceNames[0]}</Title>
        </TitleSpacer>
        {prioritisedPlaceNames.slice(1).join(', ')}
        <ItemPage>
          <ItemDetailsContainer>
            <GridColumn>
              {place.people_born_here && (
                <PlaceDetail label={t('People born here')}>
                  {place.people_born_here.map(
                    (person: Person) => (linkToPerson(person, t))
                  )}
                </PlaceDetail>
              )}
              {place.people_resident_here && (
                <PlaceDetail label={t('People resident here')}>
                  {place.people_resident_here.map(
                    (person: Person) => (linkToPerson(person, t))
                  )}
                </PlaceDetail>
              )}
              {place.documents_collected_here && (
                <PlaceDetail label={t('Documents collected here')}>
                  {place.documents_collected_here.map(document => {
                    return (
                      <>
                        <ResourceLink
                          route="/item/"
                          linkText={document.title}
                          id={document.new_id}
                      /><br/>
                      </>
                    );
                  })}
                </PlaceDetail>
              )}
            </GridColumn>
          </ItemDetailsContainer>
          <LeafletMap width={'40%'} data={[place]} zoom={6} />
        </ItemPage>
      </PageContainer>
    );
  }

  return (
    <Loading>
      <div className="loader"></div>
    </Loading>
  );
};
