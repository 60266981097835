import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getPlaceNames } from '../utils/getPlaceNamePriority';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESPerson, ESDocument } from '../hooks/useSearch';


interface PersonCardProps {
  result: ESPerson;
}

export const PersonCard: React.FC<PersonCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function getPersonName(person: ESPerson): string {
    if (!person.canonical_name && !person.name) return t("Unknown Person Name");
    return (
      person.canonical_name ||
      Object.values(person.name)
        .filter(name => name.name.includes(locale))
        .join(', ')
    );
  }

  function getAllNames(person: ESPerson): string[] {
    return person.name
      .filter(name => name.name !== person.canonical_name)
      .map(name => name.name);
  }

  function getPersonRoles(person: ESPerson): Set<string> {
    return new Set(person.personrole.map(role => role.role));
  }

  function getPlacesOfResidence(person: ESPerson): string[] {
    return Array.from(new Set(person.personrole.map(role => getPlaceNames(role.place_of_residence, locale).join(', '))));
  }

  const otherNames = getAllNames(result);
  const roles = getPersonRoles(result);
  const placesOfResidence = getPlacesOfResidence(result);

  return (
    <DisplayCardContainerLinkResults to={`/people/${result.id}`} target={"_blank"} rel={"noopener noreferrer"} backgroundColor='#efffee'>
      <SongStoryTitleContainer>
        <DisplayCardTitle>{result.canonical_name}</DisplayCardTitle>
        <DisplayCardIcon>
        Person
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" height={'1.5rem'}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>
            {otherNames.length > 0 && (
                <TileDetail label={t('Other Names')}>
                  {otherNames.map((name, index) => (
                    <div key={index}>{name}</div>
                  ))}
                </TileDetail>
            )}

            {roles && (
                <TileDetail label={t('Roles')}>
                  {[...roles].map((role, index) => (
                    <div key={index}>{role}</div>
                  ))}
                </TileDetail>
            )}

            {result.associated_documents.length > 0 && result.associated_documents[0].title && (
                <TileDetail label={t('Associated Documents')}>
                  {result.associated_documents.map((document, index) => (
                    <div key={index}>{document.title}</div>
                  ))}
                </TileDetail>
            )}

            {result.date_of_birth && (
                <TileDetail label={t('Date of Birth')}>
                  {result.date_of_birth}
                </TileDetail>
            )}

            {result.date_of_death && (
                <TileDetail label={t('Date of Death')}>
                  {result.date_of_death}
                </TileDetail>
            )}

            {placesOfResidence.length > 0 && placesOfResidence[0] && (
                <TileDetail label={t('Place of Residence')}>
                  {placesOfResidence.map((place, index) => (
                    <div key={index}>{place}</div>
                  ))}
                </TileDetail>
            )}

        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
