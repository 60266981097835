import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../components/Pagination';
import { Grid } from '../components/Grid';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { usePeople } from '../hooks/usePeople';
import { PeopleListTile } from '../components/PeopleListTile';
import { AlphabetBar } from '../components/AlphabetBar';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { withDefault } from 'serialize-query-params';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

export const People: React.FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [initial, setInitial] = useQueryParam(
    'initial',
    withDefault(StringParam, 'A')
  );
  const { data } = usePeople(page, initial);

  const handlePageSelect = (newPage: number) => {
    setPage(newPage);
  };

  const handleInitialSelect = (newInitial: string) => {
    setPage(1);
    setInitial(newInitial);
  };

  return (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/people">{t('People')}</Link>
      </BreadCrumbs>
      <TitleSpacer>
        <Title>{t('People')}</Title>
      </TitleSpacer>
      {data && (
        <>
          <AlphabetBar selected={initial} onSelect={handleInitialSelect} />
          <Pagination
            page={Number(page)}
            onSelect={handlePageSelect}
            totalPages={data.pagination.totalPages}
          />
          <Grid>
            {data && data.results.map(person => <PeopleListTile key={person.id} {...person} />)}
          </Grid>
          <Pagination
            page={Number(page)}
            onSelect={handlePageSelect}
            totalPages={data.pagination.totalPages}
          />
        </>
      )}
    </PageContainer>
  );
};
