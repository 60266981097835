import i18n from 'i18next';
function getLangWithMod(
  lang_obj: LangType,
  filter_code: 'original' | 'reference' | 'translation'
): string {
  const key: keyof LangType = `${filter_code}_modification_code` as keyof LangType;
  const val: string = lang_obj[key] || '';
  return val
    ? `${i18n.t(lang_obj.language_name)} ${i18n.t(val)}`
    : i18n.t(lang_obj.language_name);
}

export const NO_ORIG_MSG = i18n.t(
  `Original not provided in this document/recording.`
);

export function getFilteredLangs(
  langs: LangType[],
  filter_code: 'original' | 'reference' | 'translation'
): string {
  let filtered: LangType[] = [];
  if (['original', 'reference'].includes(filter_code)) {
    filtered = langs.filter(
      lang => lang.language_type_code === filter_code.slice(0, 1)
    );
  } else if (filter_code === 'translation') {
    filtered = langs.filter(
      lang => lang.language_type_code.slice(0, 1) === filter_code.slice(0, 1)
    );
  }
  if (!filtered[0] && filter_code === 'original') {
    return NO_ORIG_MSG;
  }
  return filtered.map(obj => getLangWithMod(obj, filter_code)).join(', ');
}
