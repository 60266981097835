// components/DynamicPage.tsx
import React, { useEffect } from 'react';
import { SubTitleText, TitleText } from '../atoms/TitleText';
import { FullWidthDisplayCardContainer, DisplayCardTitle, DisplayCardText } from '../components/DisplayCard';

interface FileUploadBlockProps {
  fileUpload: {
    title: string;
    file: string;
  };
}

const FileDownload: React.FC<FileUploadBlockProps> = ({ fileUpload }) => (
  <FullWidthDisplayCardContainer>
    <DisplayCardTitle>{fileUpload.title}</DisplayCardTitle>
    <SubTitleText>
      <a
        href={fileUpload.file}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>Download</button>
      </a>
    </SubTitleText>
  </FullWidthDisplayCardContainer>
);

export { FileDownload };