import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getESPlaceNamePriority } from '../utils/getPlaceNamePriority';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESDocument, PersonType } from '../hooks/useSearch';

interface DocumentCardProps {
  result: ESDocument;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function listOfPeoplesNames(peopleInRoles: PersonType[]): string {
    return peopleInRoles
      .map(person => {
        if (!person.canonical_name && !person.name) return t("Unknown Person Name");
        return (
          person.canonical_name ||
          Object.values(person.name)
            .filter(name => name.name.includes(locale))
            .join(', ')
        );
      })
      .join(', ');
  }

  return (
    <DisplayCardContainerLinkResults to={`/item/${result.new_id}`} target={"_blank"} rel={"noopener noreferrer"} backgroundColor='#eef5ff'>
      <SongStoryTitleContainer>
        <DisplayCardTitle>{result.title ?? t('Untitled')}</DisplayCardTitle>
        <Id>ID: {result.new_id}</Id>
        <DisplayCardIcon>
          Document
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" height={'1.5rem'}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
          </svg>
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>
          {result.collection_event && result.collection_event.eventrole && result.collection_event.eventrole.map((eventRole, index) => (
            <TileDetail key={index} label={t(eventRole.role.split(' ')[0])}>
              {listOfPeoplesNames([eventRole.person])}
            </TileDetail>
          ))}
          {result.collection_event.place_of_collection && (
            <TileDetail label={t('Location')}>
              {[
                ...getESPlaceNamePriority(result.collection_event.place_of_collection, locale),
              ].join(', ')}
            </TileDetail>
          )}
          {(result.collection_event && (
            result.collection_event.collection_date || 
            result.collection_event.collection_range_start_date ||
            result.collection_event.collection_range_end_date
          )) && (
            <TileDetail label={t('Date')}>
              { result.collection_event.collection_date || 
              `${result.collection_event.collection_range_start_date} - ${result.collection_event.collection_range_end_date}`}
            </TileDetail>
          )}
          {result.institution && result.institution.institution_name && (
            <TileDetail label={t('Archive')}>{result.institution.institution_name}</TileDetail>
          )}
          {result.collection && result.collection.collection_name && (
            <TileDetail label={t('Collection')}>{result.collection.collection_name}</TileDetail>
          )}
        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
