import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

type PeopleQueryResponse = {
  pagination: QueryPagination;
  results: Person[];
};

export function usePeople(page: number = 1, initial: string = 'A') {
  const base = useAPIBase();
  return useQuery<PeopleQueryResponse>(
    ['people', { page, initial }],
    async () => {
      return fetch(`${base}/person/${initial}/?page=${page}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return null;
        })
        .then(data => {
          return data;
        });
    },
    { keepPreviousData: true, staleTime: 1000 * 60 }
  );
}
