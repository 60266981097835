function buildPlottablePlace(place: Place): PlottablePlace {
  const { id, latitude_float, longitude_float } = place;
  return { id, latitude_float, longitude_float };
}

function removeDuplicates<T extends { id: number }>(array: T[]): T[] {
  // https://stackoverflow.com/a/56757215/3600105
  return array.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
}

function validLatLong(place: Place): boolean  {
  if (place && place.latitude_float && place.longitude_float) return true;
  return false;
}

export function getPlacesForItem(item: Item): PlottablePlace[] {
  const places = [];

  if (item.place_of_collection && validLatLong(item.place_of_collection)) {
    places.push(buildPlottablePlace(item.place_of_collection));
  }

  if (item.people) {
    Object.values(item.people).forEach(people => {
      people.forEach(person => {
        if (person.place_of_birth && validLatLong(person.place_of_birth)) {  
          places.push(buildPlottablePlace(person.place_of_birth));
        }

        if (person.places_of_residence) {
          person.places_of_residence.forEach(place => {
            if (validLatLong(place.place))  {
              places.push(buildPlottablePlace(place.place));
            }
          });
        }
      });
    });
  }
  return removeDuplicates(places);
}
 