import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

const FIFTEEN_MINUTES = 1000 * 60 * 15;

export function usePersonInitials() {
  const base = useAPIBase();
  return useQuery<string[]>(
    'initials',
    async () => {
      return fetch(`${base}/person/initials`)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return null;
        })
        .then(({ initials }) => initials);
    },
    { staleTime: FIFTEEN_MINUTES }
  );
}
