import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../atoms/Dropdown';
import { useFacets } from '../hooks/useFacets';

export type LanguageDropdownProps = {
  selected?: Language;
  onChange: (selection: Language) => void;
};

type LanguageDropdownOption = {
  name: string;
  value: Language;
};

export function LanguageDropdown(props: LanguageDropdownProps) {
  const { t } = useTranslation();
  const { data } = useFacets({
    pendingFacets: {}
  });

  const options = data?.facets.lang && data.facets.lang.map(option => (
    { name: t(option.label_en), value: option.value ? option.value.toString() : ""} 
  )) 

  return (
    <Dropdown
      placeholder={t('Language')}
      options={options ? options : []}
      varient={'outlined'}
      allowNoneOption={true}
      {...props}
    />
  );
}
