import React from 'react';
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
`;

export const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;
