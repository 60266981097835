import styled from 'styled-components';

export const TitleText = styled.h1`
   {
    font-size: 2rem;
    font-weight: normal;
  }
`;

export const SubTitleText = styled.h2`
   {
    font-size: 1rem;
    font-weight: normal;
  }
`;
