import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSearch, { ElasticResponse } from '../hooks/useSearch';
import { useLocale } from '../hooks/use-locale';
import { Collapsible } from './Collapsible';
import { Checkbox, CheckboxGroup, Select } from '../atoms/CustomForms';
import { personFields } from '../utils/esFieldNames';

const HrMargin = styled.hr`
  margin: 1em;
`;

interface LayTypeFacetsProps {
  searchResults: ElasticResponse;
  searchParams: URLSearchParams;
}

export const LayTypeFacets: React.FC<LayTypeFacetsProps> = ({
  searchResults,
  searchParams,
}) => {
  const { t } = useTranslation();
  const locale = useLocale() || 'en';
  const languageLookup: { [key: string]: string } = {
    en: 'English',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gv: 'Manx',
  };

  

  return (
    <>
    </>
  );
};
