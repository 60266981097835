import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NotFoundMessage = styled.div`
  padding: 1rem;
`;

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return <NotFoundMessage>{t('404 Not Found Message')}</NotFoundMessage>;
};
