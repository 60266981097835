import React, { useState, useEffect } from 'react';
import { useLocation, Link} from 'react-router-dom';
import { useAPIBase } from '../hooks/use-api-base';
import { useFetch } from '../hooks/use-fetch-data';
import { Grid } from '../components/Grid';
import {
  DisplayCardContainerLink,
  DisplayCardTitle,
} from '../components/DisplayCard';

import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs, Truncate } from '../atoms/BreadCrumbs';
import { Loading } from '../atoms/loading';
import { useTranslation } from 'react-i18next';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

export const Institution: React.FC = () => {
  const { pathname } = useLocation();
  const base = useAPIBase();
  const { t } = useTranslation();
  const [data, setData] = useState<any>();

  useEffect(() => {
    (async () => {
      const data = await useFetch(
        `${base}/institution/${pathname.split('/').pop()}/`
      );
      if (data) setData(data);
    })();
  }, []);

  return !data ? (
    <Loading>
      <div className="loader"></div>
    </Loading>
  ) : (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/archives">{t('Archives')}</Link>
        {'>'}
        <Truncate>
          <Link to={pathname}>{data.institution_name}</Link>
        </Truncate>
      </BreadCrumbs>
      <TitleSpacer>
        <Title>{data.institution_name}</Title>
      </TitleSpacer>
      <Grid>
        {data && data.collections ? (
          data.collections.map((collection: any, index: number) => (
            <DisplayCardContainerLink
              key={index}
              to={`/collection/${collection.url.split('/').splice(-2)[0]}`}
            >
              <DisplayCardTitle>{collection.collection_name}</DisplayCardTitle>
            </DisplayCardContainerLink>
          ))
        ) : (
          null
        )}
      </Grid>
    </PageContainer>
  );
};
