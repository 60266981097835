import React from 'react';
import { NavBar } from './components/NavBar';
import { Trans, useTranslation } from 'react-i18next';
import { Footer, FooterText, FooterImage } from './components/Footer';
import { createGlobalStyle } from 'styled-components';
// import { HashRouter, Route, useLocation } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    max-width: 1024px;
    margin: auto;
    background-color: #f3f3f3;
  }

  #root {
    background-color: white;
    overflow: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

const queryClient = new QueryClient();

export const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
        >
          <GlobalStyles />
          <NavBar />
          {/* TODO move all this into the footer component? */}
          <Footer>
            <FooterImage src='/user_files/Images/ESP_Logo.jpg' alt='Government of Ireland Immigrant Support Programme Logo' />
            <FooterImage src='/user_files/Images/harvard-logo.png' alt='Harvard University Logo' />
            <FooterText>{t('Contact')}: fionnfolklore@fas.harvard.edu</FooterText>
          </Footer>
        </QueryParamProvider>
      </HashRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
