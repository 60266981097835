import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../hooks/use-locale';
import { getPlaceNamePriority } from '../../utils/getPlaceNamePriority';

type PlaceInfoProps = {
  place: Place;
};

export const PlaceInfo = ({ place }: PlaceInfoProps) => {
  const language = useLocale();
  const { t } = useTranslation();
  const [prioritisedPlaceNames, setPrioritisedPlaceNames] = useState<string[]>(
    []
  );

  useEffect(() => {
    setPrioritisedPlaceNames(getPlaceNamePriority(place, language));
  }, [place, language]);

  const getLocaleName = (listOfNames: any[]) => {
    const nameInSelectedLang = listOfNames.find(
      name => name.language === language
    );
    return nameInSelectedLang && nameInSelectedLang.name
      ? nameInSelectedLang.name
      : listOfNames[0].name;
  };

  return (
    <>
      <h3>
        <Link to={`/places/${place.id}`}>
          {prioritisedPlaceNames[0] || 'Untitled place'}
        </Link>
      </h3>
      <p>{prioritisedPlaceNames.slice(1).join(', ')}</p>
      {place.people_born_here?.length && (
        <p>
          {t('People born here')}: {place.people_born_here.length}
        </p>
      )}
      {place.people_resident_here?.length && (
        <p>
          {t('People resident here')}: {place.people_resident_here.length}
        </p>
      )}
      {place.documents_collected_here?.length && (
        <p>
          {t('Documents collected here')}:{' '}
          {place.documents_collected_here.length}
        </p>
      )}
    </>
  );
};
