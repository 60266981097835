import { stringifyUrl } from 'query-string';
import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export type FacetParams = {
  pendingFacets: { [key: string]: Set<string>};
};

export function useFacets(facetParams: FacetParams) {
  let facetQuery: string = '';
  let pf: {[index: string]: any} = {};
  pf = facetParams.pendingFacets;
  for (const param of Object.keys(pf))  {
    facetQuery = facetQuery + `?${param}=${Array.from(pf[param]).join(",")}`
  }
  const base = useAPIBase();
  return useQuery(['facets', facetParams], async () => {
    return fetch(
      stringifyUrl({
        url: `${base}/facets/`,
        query: {}
      }) + facetQuery
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data;
      });
  });
}
