import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSearch, { ElasticResponse, BucketType } from '../hooks/useSearch';
import { useLocale } from '../hooks/use-locale';
import { Collapsible } from '../components/Collapsible';
import { Checkbox, CheckboxGroup, Select } from '../atoms/CustomForms';
import { storyFields } from '../utils/esFieldNames';

const HrMargin = styled.hr`
  margin: 1em;
`;

interface DocumentFacetsProps {
  searchResults: ElasticResponse;
  searchParams: URLSearchParams;
}

export const DocumentFacets: React.FC<DocumentFacetsProps> = ({
  searchResults,
  searchParams,
}) => {
  const { t } = useTranslation();
  const locale = useLocale() || 'en';
  const languageLookup: { [key: string]: string } = {
    en: 'English',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gv: 'Manx',
  };

  function getDateOptions(rangeType: string) {
    let field;
    let agg;
    let options: {value: string; name: string}[] = [];
    let selected;
    if (rangeType === 'from') {
      field = storyFields.dateRange.from.name;
      agg = storyFields.dateRange.from.agg;
    } else {
      field = storyFields.dateRange.to.name;
      agg = storyFields.dateRange.to.agg;
    }
    const currentOption = searchParams.get(field);
    if (currentOption) {
      selected = {
        value: currentOption,
        name: new Date(parseInt(currentOption)).getFullYear().toString(),
      }
    }
    options = options.concat(searchResults.aggregations[agg].buckets.map((field) => {
      return {
        value: field.key,
        name: new Date(field.key).getFullYear().toString(),
      };
    }));
    if (rangeType === 'to' && selected) {
      options.push(selected);
    } else if (rangeType === 'from' && selected) {
      options.unshift(selected);
    }
    return options;
  }

  return (
    <>
      <HrMargin />

      <CheckboxGroup
        checkboxes={[
          {
            name: storyFields.digitised.name,
            label: `${t(storyFields.digitised.label)} (${
              searchResults.aggregations[storyFields.digitised.agg]
                ?.buckets?.[0]?.doc_count ?? 0
            })`,
            checked: searchParams.has(storyFields.digitised.name),
          },
          {
            name: storyFields.summaryExists.name,
            label: `${t(storyFields.summaryExists.label)} (${
              searchResults.aggregations[storyFields.summaryExists.agg]
                ?.buckets?.[0]?.doc_count ?? 0
            })`,
            checked: searchParams.has(storyFields.summaryExists.name),
          },
        ]}
      />

      <Select
        name={storyFields.laytype.name}
        label={t(storyFields.laytype.label)}
        options={searchResults.aggregations[
          storyFields.laytype.agg
        ].buckets.map((bucket) => {
          return {
            value: bucket.key,
            name: `${bucket.labels.hits.hits[0]._source[storyFields.laytype.agg_label]} (${bucket.doc_count})`,
          };
        })}
        selected={searchParams.get(storyFields.laytype.name)}
      />

      <HrMargin />

      <Collapsible
        label={t(storyFields.language.label)}
        open={!!searchParams.get(storyFields.language.name)}
        arrow={true}
      >
        <CheckboxGroup
          indent={true}
          checkboxes={searchResults.aggregations[
            storyFields.language.agg
          ].buckets.map((field, index) => {
            return {
              name: storyFields.language.name,
              label: `${t(languageLookup[field.key])} (${
                searchResults.aggregations.languages.buckets[
                  index
                ]?.doc_count || 0
              })`,
              checked: Boolean(
                searchParams.get(storyFields.language.name) === field.key
              ),
              value: field.key,
            };
          })}
        />
      </Collapsible>

      <Collapsible
        label={t('Fianna')}
        open={storyFields.fianna.some((field) => searchParams.has(field.name))}
        arrow={true}
      >
        <CheckboxGroup
          indent={true}
          checkboxes={storyFields.fianna.map((field, index) => {
            return {
              name: field.name,
              label: `${t(field.label)} (${
                searchResults.aggregations[field.agg].buckets[index]
                  ?.doc_count || 0
              })`,
              checked: searchParams.has(field.name),
            };
          })}
        />
      </Collapsible>

      <Collapsible
        label={t(storyFields.form.label)}
        open={!!searchParams.get(storyFields.form.name)}
        arrow={true}
      >
        <CheckboxGroup
          indent={true}
          checkboxes={searchResults.aggregations.forms.buckets.map((bucket) => {
            const checkedValues = searchParams.getAll(
              storyFields.form.name
            );
            return {
              name: storyFields.form.name,
              label: `${bucket.labels.hits.hits[0]._source.form[0].form[storyFields.form[locale].agg]} (${bucket.doc_count})`,
              checked: checkedValues.includes(bucket.key),
              value: bucket.key,
            };
          })}
        />
      </Collapsible>

      <Collapsible
        label={t(storyFields.dateRange.label)}
        open={
          searchParams.has(storyFields.dateRange.from.name) ||
          searchParams.has(storyFields.dateRange.to.name)
        }
        arrow={true}
      >
        <Select
          name={storyFields.dateRange.from.name}
          label={t('From')}
          options={getDateOptions('from')}
          selected={searchParams.get(storyFields.dateRange.from.name)}
        />
        <Select
          name={storyFields.dateRange.to.name}
          label={t('To')}
          options={getDateOptions('to')}
          selected={searchParams.get(storyFields.dateRange.to.name)}
        />
      </Collapsible>

      <Collapsible
        label={t(storyFields.location.label)}
        open={
          searchParams.has(storyFields.location.country[locale].name) ||
          searchParams.has(storyFields.location.region[locale].name) ||
          searchParams.has(storyFields.location.county[locale].name) ||
          searchParams.has(storyFields.location.barony[locale].name) ||
          searchParams.has(storyFields.location.parish[locale].name)
        }
        arrow={true}
      >
        <Select
          name={storyFields.location.country[locale].name}
          label={t('Country')}
          options={searchResults.aggregations[
            storyFields.location.country[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(storyFields.location.country[locale].name)}
        />
        <Select
          name={storyFields.location.region[locale].name}
          label={t(storyFields.location.region.label)}
          options={searchResults.aggregations[
            storyFields.location.region[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(storyFields.location.region[locale].name)}
        />
        <Select
          name={storyFields.location.county[locale].name}
          label={t(storyFields.location.county.label)}
          options={searchResults.aggregations[
            storyFields.location.county[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
        />
        <Select
          name={storyFields.location.barony[locale].name}
          label={t(storyFields.location.barony.label)}
          options={searchResults.aggregations[
            storyFields.location.barony[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
        />
        <Select
          name={storyFields.location.parish[locale].name}
          label={t(storyFields.location.parish.label)}
          options={searchResults.aggregations[
            storyFields.location.parish[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
        />
        <Select
          name={storyFields.location.town[locale].name}
          label={t(storyFields.location.town.label)}
          options={searchResults.aggregations[
            storyFields.location.town[locale].agg
          ].buckets.map((field, index) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
        />
      </Collapsible>

      <Collapsible
        label={t(storyFields.archive.label)}
        open={!!searchParams.get(storyFields.archive.name)}
        arrow={true}
      >
        <CheckboxGroup
          indent={true}
          checkboxes={searchResults.aggregations[
            storyFields.archive.agg
          ].buckets.map((bucket, index) => {
            return {
              name: storyFields.archive.name,
              label: `${bucket.labels.hits.hits[0]._source.institution.institution_name} (${bucket.doc_count})`,
              checked: searchParams.get(storyFields.archive.name) === bucket.key,
              value: bucket.key,
            };
          })}
        />
      </Collapsible>
    </>
  );
};
