import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getPlaceNamePriority } from '../utils/getPlaceNamePriority';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';
import { DisplayCardTitle, DisplayCardSubtitleItalics } from './DisplayCard';
import { Grid, GridColumn } from './Grid';
import { PersonDisplayCardContainerLink } from './PeopleListTile.style';
import { TileDetail } from './TileDetail';

export type PeopleListTileProps = Person;

export function PeopleListTile({
  canonical_name,
  filtered_names,
  place_of_birth,
  lifespan,
  date_of_birth,
  date_of_death,
  roles,
  id,
}: PeopleListTileProps) {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <PersonDisplayCardContainerLink key={id} to={`/people/${id}`}>
      <DisplayCardTitle>{canonical_name}</DisplayCardTitle>
      <DisplayCardSubtitleItalics>
        {filtered_names.map(name => name.name).join('/')}
      </DisplayCardSubtitleItalics>
      <Grid>
        <GridColumn>
          {place_of_birth && (
            <TileDetail label={t('Birthplace')}>
              {[
                ...getPlaceNamePriority(place_of_birth, locale),
                getLocaleKeyVal(place_of_birth.region, 'region_name', locale),
                getLocaleKeyVal(place_of_birth.country, 'country_name', locale)
              ].join(', ')}
            </TileDetail>
          )}
          {(lifespan || date_of_birth || date_of_death) && (
            <TileDetail label="lifespan">
              {lifespan || `${date_of_birth || '?'} - ${date_of_death || '?'}`}
            </TileDetail>
          )}
          {roles && (
            <TileDetail label={t('Roles')}>
              {Array.from(
                new Set(Object.keys(roles).map(role => t(role)))
              ).join(', ')}
            </TileDetail>
          )}
        </GridColumn>
      </Grid>
    </PersonDisplayCardContainerLink>
  );
}
