import React from 'react';
import { KeyValuePair, Label30, Value70 } from '../atoms/StyledShared';

type DetailProps = {
  label: string;
  children: React.ReactNode;
};

export function TileDetail({ label, children }: DetailProps) {
  return (
    <KeyValuePair>
      <Label30>{label}</Label30>
      <Value70>{children}</Value70>
    </KeyValuePair>
  );
}
