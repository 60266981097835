export const useFetch = async (url: string ) => {
  let results: any;
  await fetch(url)
    .then(response => {
      if (response.status > 400) {
      }
      return response.json();
    })
    .then(data => {
      results = data;
    });
  return results;
};
