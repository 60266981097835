import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSearch, { ElasticResponse } from '../hooks/useSearch';
import { useLocale } from '../hooks/use-locale';
import { Collapsible } from '../components/Collapsible';
import { Checkbox, CheckboxGroup, Select } from '../atoms/CustomForms';
import { personFields } from '../utils/esFieldNames';

const HrMargin = styled.hr`
  margin: 1em;
`;

interface PersonFacetsProps {
  searchResults: ElasticResponse;
  searchParams: URLSearchParams;
}

export const PersonFacets: React.FC<PersonFacetsProps> = ({
  searchResults,
  searchParams,
}) => {
  const { t } = useTranslation();
  const locale = useLocale() || 'en';
  const languageLookup: { [key: string]: string } = {
    en: 'English',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gv: 'Manx',
  };

  

  return (
    <>
      <HrMargin />

      {searchResults.aggregations[personFields.dateOfBirth.agg].buckets.length > 0 && (
        <Select
          name={personFields.dateOfBirth.name}
          label={t(personFields.dateOfBirth.label)}
          options={searchResults.aggregations[
            personFields.dateOfBirth.agg
          ].buckets.map((field) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(personFields.dateOfBirth.name)}
        />
      )}

      <Select
        name={personFields.associatedDocuments.name}
        label={t(personFields.associatedDocuments.label)}
        options={searchResults.aggregations[
          personFields.associatedDocuments.agg
        ].buckets.map((field) => {
          return {
            value: field.key,
            name: `${field.key} (${field.doc_count})`,
          };
        })}
        selected={searchParams.get(personFields.associatedDocuments.name)}
      />

      {searchResults.aggregations[personFields.role.agg].buckets.length > 0 && (
        <Select
        name={personFields.role.name}
        label={t(personFields.role.label)}
        options={searchResults.aggregations[
          personFields.role.agg
        ].buckets.map((field) => {
          return {
            value: field.key,
            name: `${field.key} (${field.doc_count})`,
          };
        })}
        selected={searchParams.get(personFields.role.name)}
      />
      )}

      {searchResults.aggregations[personFields.relationship.agg].buckets.length > 0 && (
        <Select
        name={personFields.relationship.name}
        label={t(personFields.relationship.label)}
        options={searchResults.aggregations[
          personFields.relationship.agg
        ].buckets.map((field) => {
          return {
            value: field.key,
            name: `${field.key} (${field.doc_count})`,
          };
        })}
        selected={searchParams.get(personFields.relationship.name)}
      />
      )}
      

      <Collapsible label={t('Place of Birth')} 
        open={
          searchParams.has(personFields.countryOfBirth[locale].name) ||
          searchParams.has(personFields.regionOfBirth[locale].name) ||
          searchParams.has(personFields.countyOfBirth[locale].name) ||
          searchParams.has(personFields.baronyOfBirth[locale].name) ||
          searchParams.has(personFields.parishOfBirth[locale].name) ||
          searchParams.has(personFields.townOfBirth[locale].name)
        } 
        arrow={true}>
        {searchResults.aggregations[personFields.countryOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.countryOfBirth[locale].name}
            label={t(personFields.countryOfBirth.label)}
            options={searchResults.aggregations[
              personFields.countryOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.countryOfBirth[locale].name)}
          />
        )}
        {searchResults.aggregations[personFields.regionOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.regionOfBirth[locale].name}
            label={t(personFields.regionOfBirth.label)}
            options={searchResults.aggregations[
              personFields.regionOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.regionOfBirth[locale].name)}
          />
        )}
        {searchResults.aggregations[personFields.countyOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.countyOfBirth[locale].name}
            label={t(personFields.countyOfBirth.label)}
            options={searchResults.aggregations[
              personFields.countyOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.countyOfBirth[locale].name)}
          />
        )}
        {searchResults.aggregations[personFields.baronyOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.baronyOfBirth[locale].name}
            label={t(personFields.baronyOfBirth.label)}
            options={searchResults.aggregations[
              personFields.baronyOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.baronyOfBirth[locale].name)}
          />
        )}
        {searchResults.aggregations[personFields.parishOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.parishOfBirth[locale].name}
            label={t(personFields.parishOfBirth.label)}
            options={searchResults.aggregations[
              personFields.parishOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.parishOfBirth[locale].name)}
          />
        )}
        {searchResults.aggregations[personFields.townOfBirth[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.townOfBirth[locale].name}
            label={t(personFields.townOfBirth.label)}
            options={searchResults.aggregations[
              personFields.townOfBirth[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.townOfBirth[locale].name)}
          />
        )}
      </Collapsible>

      <Collapsible 
        label={t('Place of Residence')} 
        open={
          searchParams.has(personFields.countryOfResidence[locale].name) ||
          searchParams.has(personFields.regionOfResidence[locale].name) ||
          searchParams.has(personFields.countyOfResidence[locale].name) ||
          searchParams.has(personFields.baronyOfResidence[locale].name) ||
          searchParams.has(personFields.parishOfResidence[locale].name) ||
          searchParams.has(personFields.townOfResidence[locale].name)
        } 
        arrow={true}>
        {searchResults.aggregations[personFields.countryOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.countryOfResidence[locale].name}
            label={t(personFields.countryOfResidence.label)}
            options={searchResults.aggregations[
              personFields.countryOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.countryOfResidence[locale].name)}
          />
        )}

        {searchResults.aggregations[personFields.regionOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.regionOfResidence[locale].name}
            label={t(personFields.regionOfResidence.label)}
            options={searchResults.aggregations[
              personFields.regionOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.regionOfResidence[locale].name)}
          />
        )}

        {searchResults.aggregations[personFields.countyOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.countyOfResidence[locale].name}
            label={t(personFields.countyOfResidence.label)}
            options={searchResults.aggregations[
              personFields.countyOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.countyOfResidence[locale].name)}
          />
        )}

        {searchResults.aggregations[personFields.baronyOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.baronyOfResidence[locale].name}
            label={t(personFields.baronyOfResidence.label)}
            options={searchResults.aggregations[
              personFields.baronyOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.baronyOfResidence[locale].name)}
          />
        )}

        {searchResults.aggregations[personFields.parishOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.parishOfResidence[locale].name}
            label={t(personFields.parishOfResidence.label)}
            options={searchResults.aggregations[
              personFields.parishOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.parishOfResidence[locale].name)}
          />
        )}

        {searchResults.aggregations[personFields.townOfResidence[locale].agg].buckets.length > 0 && (
          <Select
            name={personFields.townOfResidence[locale].name}
            label={t(personFields.townOfResidence.label)}
            options={searchResults.aggregations[
              personFields.townOfResidence[locale].agg
            ].buckets.map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.townOfResidence[locale].name)}
          />
        )}
      </Collapsible>
    
    </>
  );
};
