import React from 'react';
import styled from 'styled-components';
import { PageContainer } from '../atoms/PageContainer';
import { SearchBoxLarge } from './SearchBox';
import { useTranslation } from 'react-i18next';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

export const HomePageSearchContainer = styled.div`
  background-color: #e3f2fd;
`;

export const HomePageSearch: React.FC = () => {
  const { t } = useTranslation();
  return (
    <HomePageSearchContainer>
      <PageContainer>
        <TitleSpacer>
          <Title>{t('Search the catalogue')}</Title>
        </TitleSpacer>
        <SearchBoxLarge />
      </PageContainer>
    </HomePageSearchContainer>
  );
};
