import React from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { useQueryParam, NumberParam } from 'use-query-params';
import { withDefault } from 'serialize-query-params';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCollectionInstitution } from '../hooks/useCollectionInstitution';
import { useCollectionItems } from '../hooks/useCollectionItems';
import { useLocale } from '../hooks/use-locale';
import { Pagination } from '../components/Pagination';
import { GridColumn } from '../components/Grid';
import { SongStory } from '../components/SongStory';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs, Truncate } from '../atoms/BreadCrumbs';
import { Loading } from '../atoms/loading';
import {
  Title, TitleSpacer, QuillFw
} from '../atoms/StyledShared';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';

const KeyValuePair = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

const Label = styled.div`
  font-size: 16px;
  color: #616161;
  text-decoration: rgb(97, 97, 97);
  line-height: 24px;
  width: 6rem;
`;

const Value = styled.div`
  font-size: 16px;
  color: #343a40;
  text-decoration: rgb(52, 58, 64);
  line-height: 24px;
`;

export const Collection: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const params = useParams<{ id: string }>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const collectionData = useCollectionInstitution(params.id);
  const itemData = useCollectionItems(params.id, page);
  const language = useLocale();
  const collection_description = getLocaleKeyVal(collectionData.data, 'collection_description', language);
  const handlePageSelect = (newPage: number) => {
    setPage(newPage);
  };

  if (itemData.isLoading || collectionData.isLoading) {
    return (
      <Loading>
        <div className="loader"></div>
      </Loading>
    );
  }

  if (!collectionData.data || !itemData.data) return null;
  return (
    <PageContainer>
      <BreadCrumbs>
        <Link className="breadcrumb-link" to="/">{t('Home')}</Link>
        {'>'}
        <Link className="breadcrumb-link" to="/archives">{t('Archives')}</Link>
        {'>'}
        <Truncate>
          <Link className="breadcrumb-link" to={`/institution/${collectionData.data.institution.abbreviation}`}>
            {collectionData.data.institution.institution_name}
          </Link>
        </Truncate>
        {'>'}
        <Truncate>
          <Link className="breadcrumb-link" to={pathname}>{collectionData.data.collection_name}</Link>
        </Truncate>
      </BreadCrumbs>
      {collectionData.data.collection_name && (     
        <TitleSpacer>
          <Title>{collectionData.data.collection_name} ({itemData.data.pagination.totalResults})</Title>
        </TitleSpacer>)
      }
      {
        collection_description &&
          <QuillFw dangerouslySetInnerHTML={{__html: collection_description}} />
      }
      <GridColumn> 
        <Pagination
          page={Number(page)}
          onSelect={handlePageSelect}
          totalPages={itemData.data.pagination.totalPages}
        />        
        {itemData?.data && itemData.data.results &&
          itemData.data.results.map((story: any) => (
          <SongStory {...story} />
        ))}
      </GridColumn>
    </PageContainer>
  );
};
