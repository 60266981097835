import i18n from 'i18next';
import { getLocaleKeyVal } from "./getLocaleKeyVal"
export function getForm(
  forms: Form[] | null | undefined,
  language: Language
  ): string | null {
  if (forms === null || typeof forms === 'undefined') return null
  return forms.map((form: Form) => {
    return (

      `${getLocaleKeyVal(form.form, 'form', language)}${form.modifications? ' ' + form.modifications
        .map((mod: {modification: string}) => {
          return(
            i18n.t(mod.modification)
          )
        }).join(", ") : ""}`
    )
  }).join(" + ")
}
