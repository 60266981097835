import React, { useState, useEffect, useRef } from 'react';
import { Pagination } from '../components/Pagination';
import { Grid, GridColumn } from '../components/Grid';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchBoxInner } from '../components/SearchBox';
import useSearch, { ESDocument, ESPerson, ESLayType } from '../hooks/useSearch';
import { useLocale } from '../hooks/use-locale';
import { DocumentCard } from '../components/DocumentCard';
import { PersonCard } from '../components/PersonCard';
import { LayTypeCard } from '../components/LayTypeCard';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useAPIBase } from '../hooks/use-api-base';
import { Title, TitleSpacer } from '../atoms/StyledShared';
import { DocumentFacets } from '../components/DocumentFacets';
import { PersonFacets } from '../components/PersonFacets';
import { LayTypeFacets } from '../components/LayTypeFacets';
import { Select } from '../atoms/CustomForms';

const SearchPageContainer = styled(Grid)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const YGap = styled.div`
  margin: 1rem 0;
`;

const SearchForm = styled.form`
  justify-content: space-between;
  width: 28%;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin: 0.5rem 0rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ResultsContainer = styled.div`
  margin: 1rem 0;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const SearchTerm = styled.div`
  padding: 0.5rem 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ResultsTotal = styled.span`
  color: #666666;
`;

const SearchButtons = styled.span`
  margin-bottom: 1rem;
`;

export const SearchPage: React.FC = () => {
  // State

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const { data, error, isLoading } = useSearch();
  const [searchResults, setSearchResults] = useState(data);
  const location = useLocation();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search)
  );
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1
  );
  const [esIndex, setESIndex] = useState(
    searchParams.get('index') ? (searchParams.get('index') as string) : ''
  );

  // watchers
  useEffect(() => {
    if (data) {
      setSearchResults(data);
      console.log(searchResults);
    }
  }, [data]);

  useEffect(() => {
    submitSearch();
    }, [page]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setSearchParams(searchParams);
  }, [location.search]);

  const onFormChange = (ev: React.ChangeEvent<HTMLFormElement>) => {
    if (ev.target.name === 'keywords') {
      return;
    } else if (['index'].includes(ev.target.name)) {
      console.log('index changed: ', ev.target.name, ev.target.value);
      clear(['index']);
      submitSearch();
    } else {
      submitSearch();
    }
  };

  // Clear all facet form elements and the query params _except_ for the ones in the except array
  function clear(except: string[] = []) {
    if (formRef.current) {
      const inputs = formRef.current.querySelectorAll('input, select');
      inputs.forEach(input => {
        if (except.includes((input as HTMLInputElement|HTMLSelectElement).name)) {
          return;
        }
        if (input.tagName === 'SELECT') {
          (input as HTMLSelectElement).selectedIndex  = 0;
        } else {
          (input as HTMLInputElement).value = '';
        }
      });
    }
    history.replace(location.pathname);
  }

  function submitSearch() {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const searchParams = new URLSearchParams(window.location.search);
      for (let pair of formData.entries()) {
        if (pair[1] === '') {
          continue;
        }
        searchParams.append(pair[0], pair[1] as string);
      }
      for (let key of searchParams.keys()) {
        if (!formData.has(key)) {
          searchParams.delete(key);
        }
      }
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      history.replace(newUrl);
    }
  }

  return (
    <PageContainer>
      {searchResults && (
        <>
          <BreadCrumbs>
            <Link to="/">{`${t('Home')}`}</Link>
            {'>'}
            <Link to="/search">{`${t('Search')}`}</Link>
          </BreadCrumbs>
          <TitleSpacer>
            <Title>{`${t('Search')}`}</Title>
          </TitleSpacer>
          <SearchPageContainer>
            <SearchForm ref={formRef} onChange={onFormChange}>
              <input type="hidden" name="page" value={page} />
              <SearchButtons>
                <ButtonGroup size="small" aria-label="small button group">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => submitSearch()}
                  >
                    {t('Refine search')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => clear()}
                  >
                    {t('Clear filters')}
                  </Button>
                </ButtonGroup>
              </SearchButtons>

              <SearchBoxInner
                onSearch={() => submitSearch()}
                inputName="keywords"
              />

              <YGap />

              <Select
                name="index"
                label={t('Category')}
                options={[
                  { value: 'documents', name: `${t('Documents')}` },
                  { value: 'people', name: `${t('People')}` },
                  { value: 'laytypes', name: `${t('Lays')}` },
                ]}
                defaultOption={{ value: 'all', name: `${t('All')}` }} // 
                selected={esIndex}
              />
                

                {esIndex === 'documents' ? (
                  <DocumentFacets
                    searchResults={searchResults}
                    searchParams={searchParams}
                  />
                ) : esIndex === 'people' ? (
                  <PersonFacets
                    searchResults={searchResults}
                    searchParams={searchParams}
                  />
                ) : esIndex === 'laytypes' ? (
                  <LayTypeFacets
                    searchResults={searchResults}
                    searchParams={searchParams}
                  />
                ) : null}



            </SearchForm>
            <ResultsContainer>
              <Pagination
                page={page}
                totalPages={searchResults.pagination.max_page}
                onSelect={setPage}
              />
              <GridColumn>
                <SearchTerm>
                  <span>
                    {searchParams.get('keywords') && (
                      <div>
                        {t('You searched for')}: <em>{searchParams.get('keywords')}</em>
                      </div>
                    )}
                  </span>
                  {searchResults && (
                    <ResultsTotal>
                      {t('Results')} ({searchResults.hits.total.value})
                    </ResultsTotal>
                  )}
                </SearchTerm>

                {data?.hits.hits &&
                  data.hits.hits.map((result) => (
                    result._index.includes("documents") ? (
                      <DocumentCard key={result._id} result={result._source as ESDocument} />
                    ) : result._index.includes("people") ? (
                      <PersonCard key={result._id} result={result._source as ESPerson} />
                    ) : result._index.includes("laytypes") ? (
                      <LayTypeCard key={result._id} result={result._source as ESLayType} />
                    ) : null
                  ))}
              </GridColumn>
            </ResultsContainer>
          </SearchPageContainer>
          <Pagination
            page={page}
            totalPages={searchResults.pagination.max_page}
            onSelect={setPage}
          />
        </>
      )}
    </PageContainer>
  );
};
