// @flow

import React from 'react';
import withGoogleAnalytics from './GoogleAnalytics';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
// import { Button, Segment } from 'semantic-ui-react';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Button, RoundedButton, MediumRoundedButton, CallToActionLinkButton } from '../atoms/Button';
import './CookiePolicyBanner.css';


type Props = {
  onAccept: () => void,
  onDecline: () => void,
  t: (key: string) => string,
}

const CookiePolicyBanner = (props: Props) => (
  <PaddingComponent
    className='cookie-policy-banner'
  >
    <div>
      { props.t('CookiePolicyBanner.content') }
    </div>
    <div>
      <MediumRoundedButton
        onClick={props.onAccept}
        // primary
      >
        {props.t('CookiePolicyBanner.buttons.accept')}
      </MediumRoundedButton>
      <MediumRoundedButton
        onClick={props.onDecline}
        // inverted
      >
        {props.t('CookiePolicyBanner.buttons.decline')}
      </MediumRoundedButton>
    </div>
  </PaddingComponent>
);

export default withTranslation()(withGoogleAnalytics(CookiePolicyBanner));