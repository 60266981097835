// Get any names in the current language
export function getLocaleName(
    canonicalName: string|null,
    listOfNames: any[], 
    language: Language
): string {
  const name = listOfNames.filter(name => !name.alternative_name).find(name => name.language === language) 
  if (name) return name.name;
  if (canonicalName) return canonicalName;
  if (listOfNames[0] && listOfNames[0].name) return listOfNames[0].name;
  return ""
}