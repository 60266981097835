export function getWitnessMSPages(
  witnesses: DocumentWitness[] = []
  ): string | null {
    if (witnesses.length === 0) return null;
    return(
      witnesses
      .filter(witness => witness.type === "m")
      .map((witness: DocumentWitness, index: number) => {
        return `${witness.number}${witness.pages ? ' : ' + witness.pages : ''}` 
      }).join(' + ')
    );
}
