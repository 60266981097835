import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocale } from '../hooks/use-locale';
import { useLayTypes } from '../hooks/useLayTypes';
import { Grid } from '../components/Grid';
import {
  DisplayCardContainerLinkSmall,
  DisplayCardTitle,
} from '../components/DisplayCard';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { Loading } from '../atoms/loading';

const Translation = styled.div`
  margin-top: 0.5rem;
  color: #515151;
`;

export const Lays: React.FC = () => {
  const { data: laytypeList = [] } = useLayTypes();
  const language = useLocale();
  const { t } = useTranslation();

  const getLocaleLang = (obj: any, lang: string | undefined) => {
    const options = Object.keys(obj);
    let key = '';
    switch (lang) {
      case 'ga':
        key = options.find(option => option.includes('_ga')) || '';
        break;
      case 'gd':
        key = options.find(option => option.includes('_gd')) || '';
        break;
      default:
        key = 'title_en';
    }
    return key ? obj[key] : obj.title_en;
  };

  if (laytypeList) return (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/lays">{t('Stories and Songs')}</Link>
      </BreadCrumbs>
      <Grid>
        {laytypeList.map((story: any, index: number) => (
          <DisplayCardContainerLinkSmall
            key={index}
            to={`/lays/${story.fenian_tale_type ? story.fenian_tale_type.toLowerCase() : ''}`}
          >
            {/* conditionally display the value in Irish if site is in english */}
            {language === 'en' ? (
              <DisplayCardTitle>{getLocaleLang(story, 'ga')}</DisplayCardTitle>
            ) : null}
            <DisplayCardTitle>
              <Translation>{getLocaleLang(story, language)}</Translation>
            </DisplayCardTitle>
          </DisplayCardContainerLinkSmall>
        ))}
        <DisplayCardTitle />
      </Grid>
    </PageContainer>
  );
  return (
    <Loading>
      <div className="loader"></div>
    </Loading>
  );
};
