import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function useCollectionInstitution(id: string) {
  const base = useAPIBase();

  return useQuery<Collection>(['collection', id], async () => {
    return fetch(`${base}/collection_institution/${id}/`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data;
      });
  });
}
