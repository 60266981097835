import React from 'react';
import { SearchBoxSmall } from './SearchBox';
import { LanguageSwitcher } from '../atoms/LanguageSwitcher';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const TitleText = styled.div`
   {
    font-family: Adamina, serif;
    font-size: 30px;
    font-weight: normal;
    padding: 1rem 1rem;
  }
  @media (max-width: 768px) {
   font-size: 20px;
  }
`;

const TitleBarContainer = styled.div`
   {
    background-color: #0d47a1;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.375rem;
    border-bottom: 2px solid white;
    align-items: center;
  }
`;

const LogoImage = styled.img.attrs(props => ({
  src:props.src,
  alt:props.alt
}))`
  {
    float: left;
    width: 50px;
    padding-right: 5px;
    @media (max-width: 768px) {
      width: 25px;
    }
  }
`;

const SearchAndLanguageSwitcherWrapper = styled.div`
  display: flex;
`;

const PaddingElement = styled.div`
  width: 0.5rem;
`;

export const TitleBar: React.FC<{
  showSearch: boolean;
}> = ({ showSearch }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <TitleBarContainer>
      <LogoImage src='user_files/Images/logo_tranparentbackground.png' alt='Fionn Folklore Database Logo' />
      <TitleText>{t('Fionn Folklore Database')}</TitleText>
      <SearchAndLanguageSwitcherWrapper>
        <LanguageSwitcher />
        <PaddingElement />
        {showSearch ? (
          <SearchBoxSmall
            onSearch={searchValue =>
              history.push(`/search/?keywords=${searchValue}`)
            }
          />
        ) : null}
      </SearchAndLanguageSwitcherWrapper>
    </TitleBarContainer>
  );
};
