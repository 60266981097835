export function getAllNames(names: PersonName[], exceptFor?: string): string[] {
  return [
    ...new Set(
      names
        .sort((name1, name2) =>
          // Putting alternative names to the end of the list
          // https://stackoverflow.com/a/17387454/3600105
          name1.alternative_name === name2.alternative_name
            ? 0
            : name1.alternative_name
            ? 1
            : -1
        )
        .map(({ name }) => name)
        .filter(name => name !== exceptFor)
    ),
  ];
}
