import styled from 'styled-components';

export const SongStoryTitleContainer = styled.div`
  color: black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Id = styled.span`
  color: #767676;
  font-style: italic;
`;
