import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../atoms/PageContainer';
import { Collapsible } from '../components/Collapsible';
import { Grid } from '../components/Grid';
import { SubTitleText, TitleText } from '../atoms/TitleText';
import { IntroductionText } from '../atoms/IntroductionText';
import { NotFound } from './not-found';
import { usePageDetail } from '../hooks/use-page-detail';
import { RichText } from '../blocks/RichText';
import { FileDownload } from '../blocks/FileDownload';
import { SingleCard, CardGroup } from '../blocks/Card';
import HorizontalBar from '../blocks/HorizontalBar';

export const DynamicPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { content, loading, error } = usePageDetail(slug, localStorage.getItem('language') || 'en');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !content) {
    return <NotFound></NotFound>;
  }

  const renderBlock = (block: any, index: number) => (
    <React.Fragment key={index}>
      {block.text && (
        <RichText dangerouslySetInnerHTML={{ __html: block.text }} />
      )}
      {block.file_Upload && (
        <FileDownload fileUpload={block.file_Upload} />
      )}
      {block.card && <SingleCard card={block.card} />}
      {block.card_group && <CardGroup cardGroup={block.card_group} />}
      {block.collapsible && collapsibleBlock(block.collapsible, index)}
      {block.horizontal_bar && <HorizontalBar className="cms-hr"/>}
    </React.Fragment>
  );

  const collapsibleBlock = (block: any, index: number) => (
    <Collapsible key={index} label={block.label}>
      {block.content.map((innerBlock: any, innerIndex: number) => renderBlock(innerBlock, innerIndex))}
    </Collapsible>
  );

  return (
    <PageContainer>
      <Grid>
        <IntroductionText>
          <TitleText>{content.title}</TitleText>
        </IntroductionText>
      </Grid>
      <>
        {content.body.map((block: any, index: number) => (
          <Grid key={index}>
            {renderBlock(block, index)}
          </Grid>
        ))}
      </>
    </PageContainer>
  );
};