import React, { useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import styled, { css } from 'styled-components';
import { MapInfoPopup } from './MapInfoPopup';
import L from 'leaflet';
// @ts-ignore
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'; 
// @ts-ignore
import iconUrl from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';


const MapBounds = styled.div<{ width?: string; height?: string }>`
  height: 400px;
  width: ${props => (props.width ? props.width : '100%')};

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 20px;
  }

  .leaflet-container {
    height: ${props => (props.height ? props.height : '400px')};

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .leaflet-marker-icon  {
    z-index: 9999 !important;
  }
`;

const defaultLatLng: LatLngTuple = [55.076474, -5.4757107];

export const LeafletMap: React.FC<{
  width?: string;
  height?: string;
  data?: PlottablePlace[];
  zoom?: number;
}> = ({ width, height, data = [], zoom = 5 }) => {
  useEffect(() => {
    // @ts-ignore
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.prototype.options.iconRetinaUrl = iconRetinaUrl;
    L.Icon.Default.prototype.options.iconUrl = iconUrl;
    L.Icon.Default.prototype.options.shadowUrl = shadowUrl;
  }, []);

  return (
    <MapBounds id="mapid" width={width} height={height}>
      <MapContainer
        center={
          data[0] && data[0].latitude_float && data[0].longitude_float
            ? [data[0].latitude_float, data[0].longitude_float]
            : defaultLatLng
        }
        zoom={zoom}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {
          // @ts-ignore
          <MarkerClusterGroup>
            {data
              .filter(
                (point: any) => point.latitude_float || point.longitude_float
              )
              .map((point: any) => {
                return (
                  <Marker
                    key={point.id}
                    position={[point.latitude_float, point.longitude_float]}
                  >
                    <MapInfoPopup placeId={point.id} />
                  </Marker>
                );
              })}
          </MarkerClusterGroup>
        }
      </MapContainer>
    </MapBounds>
  );
};
