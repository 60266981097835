//NOTE TO STEPHEN - NOT USED - using FacetOptionsCheckboxes

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../atoms/Dropdown';
import { useLocale } from '../hooks/use-locale';
import { useLayTypes } from '../hooks/useLayTypes';

export type StorySongDropdownProps = {
  selected?: LayType['fenian_tale_type'];
  onChange: (selection: LayType['fenian_tale_type']) => void;
};

export function StorySongDropdown(props: StorySongDropdownProps) {
  const { data: laytypeList = [] } = useLayTypes();
  const { t } = useTranslation();
  const locale = useLocale();

  const getLocaleLang = (layType: LayType) => {
    switch (locale) {
      case 'ga':
      case 'gd': {
        return layType.title_ga;
      }
      default:
        return `${layType.title_ga} (${layType.title_en})`;
    }
  };

  return (
    <Dropdown
      placeholder={t('Type')}
      options={laytypeList.map(option => {
        return {
          value: option.fenian_tale_type,
          name: getLocaleLang(option),
        };
      })}
      varient={'outlined'}
      allowNoneOption={true}
      fixedWidth={300}
      {...props}
    />
  );
}
