// components/DynamicPage.tsx
import styled from 'styled-components';

const HorizontalBar = styled.hr`
    border: 0;
    display: block;
    width: 100%;
    background-color:#7b8da3;
    height: 2px;
    margin: 1rem 0;
`;
  
export default HorizontalBar;