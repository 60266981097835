import React from 'react';
import { usePersonInitials } from '../../hooks/usePersonInitials';
import { List, InitialLink } from './AlphabetBar.style';

export type AlphabetBarProps = {
  selected?: string;
  onSelect: (selected: string) => void;
};

export function AlphabetBar({ selected, onSelect }: AlphabetBarProps) {
  const { data, isLoading } = usePersonInitials();

  if (isLoading) return <div>Loading...</div>;

  return (
    <List>
      {data?.map(initial => (
        <li key={initial}>
          <InitialLink
            onClick={() => onSelect(initial)}
            selected={initial === selected}
          >
            {initial.toUpperCase()}
          </InitialLink>
        </li>
      ))}
    </List>
  );
}
