import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function useLayTypes() {
  const base = useAPIBase();
  return useQuery<LayType[]>('layTypes', async () => {
    return fetch(`${base}/laytype/`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data.results;
      });
  });
}
