import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../atoms/Dropdown';
import { useArchives } from '../hooks/useArchives';

export type ArchiveDropdownProps = {
  selected?: Institution['abbreviation'];
  onChange: (selection: Institution['abbreviation']) => void;
};

export function ArchiveDropdown(props: ArchiveDropdownProps) {
  const { data: archivesList = [] } = useArchives();
  const { t } = useTranslation();

  return (
    <Dropdown
      placeholder={t('Archive')}
      options={archivesList.map((option: any) => {
        return { value: option.abbreviation, name: option.institution_name };
      })}
      varient={'outlined'}
      allowNoneOption={true}
      {...props}
    />
  );
}
