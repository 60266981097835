const URLs = {
  prod: '/api',
  local: 'http://localhost:8000/api',
};

export const useAPIBase = () => {
  if(process.env.NODE_ENV == "development"){
    return URLs.local;
  } else {
    return `${window.location.origin}${URLs.prod}`;
  }
};
