import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '../components/Grid';
import {
  DisplayCardContainerLink,
  DisplayCardTitle,
} from '../components/DisplayCard';
import { useInstitutions } from '../hooks/useInstitutions';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { Loading } from '../atoms/loading';

export const Archives: React.FC = () => {
  const { data } = useInstitutions();
  const { t } = useTranslation();

  if (data) return (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/archives">{t('Archives')}</Link>
      </BreadCrumbs>
      <Grid>
        {data &&
          data.map((institution: Institution) => (
            <DisplayCardContainerLink
              key={institution.abbreviation}
              to={`/institution/${institution.abbreviation}`}
            >
              <DisplayCardTitle>
                {institution.institution_name}
              </DisplayCardTitle>
            </DisplayCardContainerLink>
          ))}
      </Grid>
    </PageContainer>
  );
  return (
    <Loading>
      <div className="loader"></div>
    </Loading>
  );
};
