import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { PageContainer } from '../atoms/PageContainer';
import {
  DisplayCardContainer,
  FullWidthDisplayCardContainer,
  DisplayCardTitle,
  DisplayCardSubtitle,
  DisplayCardText,
} from '../components/DisplayCard';
import { Grid } from '../components/Grid';
import { LinkButton } from '../atoms/Button';
import { TitleText, SubTitleText } from '../atoms/TitleText';
import { IntroductionText } from '../atoms/IntroductionText';
import { Link } from 'react-router-dom';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

export const Contact: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
        <Grid>
          <IntroductionText>
            <TitleSpacer>
              <Title>{t('Contact Page')}</Title>
              <SubTitleText>{t('Contact Text')}</SubTitleText>
            </TitleSpacer>
          </IntroductionText>
        </Grid>
        <Grid>
          <FullWidthDisplayCardContainer>
            <DisplayCardTitle>{t('Email')}</DisplayCardTitle>
            <DisplayCardText>
              {t(
                'Email Address'
              )}
                <br/><br/>
            </DisplayCardText>
            <DisplayCardTitle>{t('Address')}</DisplayCardTitle>
            <DisplayCardText>
              <Trans i18nKey="multiline" t = {t}> <em>{t(
                'Address Detail'
              )}</em>
              </Trans>
            </DisplayCardText>
          </FullWidthDisplayCardContainer>
        </Grid>
      </PageContainer>
  );
};
