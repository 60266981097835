import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../atoms/Dropdown';
import { useForms } from '../hooks/useForms';
import { useLocale } from '../hooks/use-locale';

export type FormDropdownProps = {
  selected?: Form['form']['abbreviation'];
  onChange: (selection: Form['form']['abbreviation']) => void;
};

export function FormDropdown(props: FormDropdownProps) {
  const { data: formsList = [] } = useForms();
  const { t } = useTranslation();
  const locale = useLocale();

  const getLocaleLang = (formType: Form['form']) => {
    switch (locale) {
      case 'ga': {
        // defaulting to english until translation provided
        return formType.form_ga ? formType.form_ga : formType.form_en;
      }
      case 'gd': {
        // defaulting to english until translation provided
        return formType.form_gd ? formType.form_gd : formType.form_en;
      }
      case 'en': {
        return formType.form_en;
      }
      default:
        return `${formType.form_ga || 'Missing translation'} (${
          formType.form_en
        })`;
    }
  };

  return (
    <Dropdown
      placeholder={t('Form')}
      options={formsList.map((option: any) => {
        return { value: option.abbreviation, name: getLocaleLang(option) };
      })}
      varient={'outlined'}
      allowNoneOption={true}
      {...props}
    />
  );
}
