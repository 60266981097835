import styled, { css } from 'styled-components';


export const InputLabel = styled.label`
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

export const Input = styled.input`
  background: transparent;
  border: 1px solid #370b0b;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 0.9em;
  line-height: 1.3em;
  width: 100%;
  max-width: 700px;
  box-shadow: none;
  &:focus {
    border-color: #333;
    outline: none;
  }
`;

export const InputBorderless = styled.input`
  background: transparent;
  border: none;
  padding: 0.5em;
  font-size: 0.9em;
  line-height: 1.3em;
  width: 100%;
  box-shadow: none;
  border: none;
  &:focus {
    border-color: #333;
    outline: none;
  }
`;

export const InputCheckboxInputContainer = styled.div<{ $checked?: boolean }>`
  background: #ddd;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  ${props =>
    props.$checked &&
    css`
      background-color: #c5e8c5;
      border-color: #6ccd55;
    `}
`;


export const InputCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  label {
    flex: 1 1 0px;
    margin: 0;
    margin-left: 1em;
  }
`;


export const InputContainer = styled.div<{
  wide?: boolean;
  fluid?: boolean;
  $error?: boolean;
}>`
  display: flex;
  flex-direction: column;
  max-width: ${props =>
    props.fluid ? '100%' : props.wide ? '550px' : '360px'};
  margin: 0.5em 0;

  ${props =>
    props.$error &&
    css`
      background: #ffeaea;
      outline: 5px solid #ffeaea;

      input,
      textarea {
        border-color: #9c2824;
      }
    `}
`;
