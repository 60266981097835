import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocale } from '../hooks/use-locale';
import { useItem } from '../hooks/useItem';
import { LeafletMap } from '../components/Map';
import { GridColumn } from '../components/Grid';
import { PersonDetails } from '../components/PersonDetails';
import { PlaceDetails } from '../components/PlaceDetails';
import { ResourceLink } from '../components/ResourceLink';
import { PageContainer } from '../atoms/PageContainer';
import { Loading } from '../atoms/loading';
import { CallToActionLinkButton } from '../atoms/Button';
import {
  ExternalLinksContainer,
  GroupedData,
  ItemDetailsContainer,
  ItemPage,
  KeyValuePair,
  Label,
  Subheading,
  Title,
  Value,
  Wrapper,
} from '../atoms/StyledShared';
import { getPlacesForItem } from '../utils/getPlacesForItem';
import { getStorySongTypes } from '../utils/getStorySongTypes';
import { getFilteredLangs } from '../utils/getFilteredLangs';
import { getForm } from '../utils/getForm';
import { getWitnessMSPages } from '../utils/getWitnessMSPages';
import { getWitnessData } from '../utils/getWitnessData';
import { getCollectionDateInfo } from '../utils/getCollectionDateInfo';
import { getOtherTellingText } from '../utils/getOtherTellingText';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';
import { UNKNOWN } from '../utils/itemUtils';

export const Item: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const locale = useLocale();
  const { t } = useTranslation();
  const { data } = useItem(parseInt(id));

  if (!data) {
    return (
      <Loading>
        <div className="loader"></div>
      </Loading>
    );
  }
  const forms = getForm(data.forms, locale);
  const storySongTypes = getStorySongTypes(data.laytypes, locale);
  const witnessMSPages = getWitnessMSPages(data.document_witness);
  const witnessDataRecNumber = getWitnessData(
    data.document_witness,
    'number',
    'r'
  );
  const witnessDataMSNotes = getWitnessData(
    data.document_witness,
    'notes',
    'm'
  );
  const witnessDataRecNotes = getWitnessData(
    data.document_witness,
    'notes',
    'r'
  );
  const witnessDataRecLength = getWitnessData(
    data.document_witness,
    'length',
    'r'
  );
  const collectionDateInfo = getCollectionDateInfo(data.collection_event);
  const referenceLang = data.doc_language
    ? getFilteredLangs(data.doc_language, 'reference')
    : '';
  const transLang = data.doc_language
    ? getFilteredLangs(data.doc_language, 'translation')
    : '';

  const collectionDateNotes = getLocaleKeyVal(data.collection_event, "misc", locale);

  if (data) return (
    <PageContainer>
      <ItemPage>
        <ItemDetailsContainer>
          <GridColumn>
            <Title>{data.title}</Title>

            <ExternalLinksContainer>
              {data.available_online.map((resource: any) => {
                return (
                  <CallToActionLinkButton>
                    <a href={resource.doc_url} target="_blank" rel="noopener">
                      {t('View / Listen Online')}
                    </a>
                  </CallToActionLinkButton>
                );
              })}
            </ExternalLinksContainer>

            <GroupedData>
              <KeyValuePair>
                <Label>{t('Database ID')}</Label>
                <Value>{data.new_id}</Value>
              </KeyValuePair>

              {storySongTypes &&
                <KeyValuePair>
                  <Label>{t('Story/Song Type(s)')} </Label>
                  <Value>
                  {
                    storySongTypes.map(storySongType => {
                      const linkText=`${storySongType.label}${storySongType.cf}`
                      return linkText && (
                        <>
                           <ResourceLink 
                            route="/lays/"
                            linkText={linkText}
                            id={storySongType.id}
                          /><br/>
                        </>
                      )
                    })
                  }
                  </Value>
                </KeyValuePair>
              }

              {/* Only show if not fenian */}
              {!data.fenian && (
                <KeyValuePair>
                  <Label> </Label>
                  <Value>{`(${t('Fianna not present.')})`}</Value>
                </KeyValuePair>
              )}

              {forms &&
              <KeyValuePair>
                <Label>{t('Form')}</Label>
                <Value>
                  {forms}
                </Value>
              </KeyValuePair>
              }

              {data.institution_name && (
                <KeyValuePair>
                  <Label>{t('Institution')}</Label>
                  <Value>
                    {data.collection &&
                    data.collection.institution &&
                    data.collection.institution.url ? (
                      <ResourceLink
                        route="/institution/"
                        linkText={data.institution_name}
                        id={data.collection.institution.abbreviation}
                      />
                    ) : (
                      data.institution_name
                    )}
                  </Value>
                </KeyValuePair>
              )}

              {data.collection && data.collection.collection_name && (
                <KeyValuePair>
                  <Label>{t('Collection')}</Label>
                  <Value>
                    {data && data.collection && data.collection.url ? (
                      <ResourceLink
                        route="/collection/"
                        linkText={data.collection.collection_name}
                        id={data.collection.id}
                      />
                    ) : (
                      data.collection.collection_name
                    )}
                  </Value>
                </KeyValuePair>
              )}
              {witnessMSPages && (
                <KeyValuePair>
                  <Label>{t('Manuscript(s) and Page(s)')}</Label>
                  <Value>{witnessMSPages}</Value>
                </KeyValuePair>
              )}
              {witnessDataMSNotes && (
                <KeyValuePair>
                  <Label>{t('Manuscript Notes')}</Label>
                  <Value>{witnessDataMSNotes}</Value>
                </KeyValuePair>
              )}
            </GroupedData>
            <GroupedData>
              {witnessDataRecNumber && (
                <KeyValuePair>
                  <Label>{t('Recording Number(s)')}</Label>
                  <Value>{witnessDataRecNumber}</Value>
                </KeyValuePair>
              )}
              {witnessDataRecNotes && (
                <KeyValuePair>
                  <Label>{t('Recording Notes')}</Label>
                  <Value>{witnessDataRecNotes}</Value>
                </KeyValuePair>
              )}
              {witnessDataRecLength && (
                <KeyValuePair>
                  <Label>{t('Recording Length')}</Label>
                  <Value>{witnessDataRecLength}</Value>
                </KeyValuePair>
              )}
              {data && data.line_word_count && (
                <KeyValuePair>
                  <Label>{t('Line/Word Count')}</Label>
                  <Value>{data.line_word_count}</Value>
                </KeyValuePair>
              )}
              {collectionDateInfo !== UNKNOWN && (
                <KeyValuePair>
                  <Label>{t('Collection Date')}</Label>
                  <Value>{collectionDateInfo}</Value>
                </KeyValuePair>
              )}
              {collectionDateNotes && (
                <KeyValuePair>
                  <Label>{t('Collection Date Notes')}</Label>
                  <Value>{collectionDateNotes}</Value>
                </KeyValuePair>
              )}
            </GroupedData>

            <GroupedData>
              <KeyValuePair>
                <Label>{t('Language of original')}</Label>
                <Value>
                  {data.doc_language && data.doc_language.length > 0
                    ? getFilteredLangs(data.doc_language, 'original')
                    : `(${t('Original not provided in this document/recording.')})`
                  }
                </Value>
              </KeyValuePair>

              {!data.original_provided &&
              <KeyValuePair>
                <Label></Label>
                <Value>
                  {`(${t('Original not provided in this document/recording.')})`}
                </Value>
              </KeyValuePair>
              }

              {referenceLang && (
                <KeyValuePair>
                  <Label>{t('Language of reference')}</Label>
                  <Value>{referenceLang}</Value>
                </KeyValuePair>
              )}

              {data.doc_language && transLang && (
                <KeyValuePair>
                  <Label>{t('Language of translation')}</Label>
                  <Value>{transLang}</Value>
                </KeyValuePair>
              )}
            </GroupedData>

            {data.people &&
              data.people.interviewee &&
              data.people.interviewee.length > 0 && (
                <Wrapper>
                  {data.people.interviewee.map((interviewee: Person, index: number) => {
                    return (
                      <GroupedData>
                        <PersonDetails
                          key={`${interviewee.id}_${index}`}
                          id={interviewee.id}
                          label={interviewee.roles ? interviewee.roles[0].role : "unknown"}
                          name={interviewee.name}
                          canonical_name={interviewee.canonical_name}
                          url={interviewee.url}
                          gender={interviewee.gender}
                          lifespan={interviewee.lifespan}
                          residence={interviewee.places_of_residence}
                          native_place={interviewee.place_of_birth}
                          place_of_birth_accuracy={interviewee.place_of_birth_accuracy}
                          roles={interviewee.roles}
                          and_or={
                            index > 0
                              ? data.collection_event.interviewees_and_or
                              : undefined
                          }
                        />
                        { data.people &&
                          data.people.source &&
                          data.people.source.length > 0 && (
                            <Wrapper>
                              <GroupedData>
                              {data.people.source.filter(
                                intervieweeSource => 
                                intervieweeSource.roles ? intervieweeSource.roles[0].role.startsWith(
                                    interviewee.roles ? interviewee.roles[0].role : "unknown")
                                : false).map((intervieweeSource: Person, index1: number) => {
                                return (
                                    <PersonDetails
                                      key={`${intervieweeSource.id}_${index1}`}
                                      id={intervieweeSource.id}
                                      label={intervieweeSource.roles ? intervieweeSource.roles[0].role : "unknown"}
                                      name={intervieweeSource.name}
                                      canonical_name={intervieweeSource.canonical_name}
                                      url={intervieweeSource.url}
                                      gender={intervieweeSource.gender}
                                      lifespan={intervieweeSource.lifespan}
                                      residence={intervieweeSource.places_of_residence}
                                      native_place={intervieweeSource.place_of_birth}
                                      place_of_birth_accuracy={intervieweeSource.place_of_birth_accuracy}
                                      roles={intervieweeSource.roles}
                                      and_or={
                                        index > 0
                                          ? data.collection_event.sources_and_or
                                          : undefined
                                      }
                                      indent={true}
                                    />
                                );
                              })}
                              </GroupedData>
                            </Wrapper>
                          )}
                      </GroupedData>
                    );
                  })}
                </Wrapper>
              )}

            {data.people &&
              data.people.collector &&
              data.people.collector.length > 0 && (
                <Wrapper>
                  {data.people.collector.map((collector: any) => {
                    return (
                      <GroupedData>
                        <PersonDetails
                          id={collector.id}
                          label={'Collector'}
                          name={collector.name}
                          canonical_name={collector.canonical_name}
                          url={collector.url}
                          gender={collector.gender}
                          lifespan={collector.lifespan}
                          roles={collector.roles}
                        />
                      </GroupedData>
                    );
                  })}
                </Wrapper>
              )}

            {data.place_of_collection && (
              <GroupedData>
                <Subheading>{t('Place Recorded')}</Subheading>
                {data.place_of_collection && (
                  <PlaceDetails
                    id={data.place_of_collection.id}
                    url={data.place_of_collection.url}
                    town_village={data.place_of_collection.town_village}
                    parish={data.place_of_collection.parish}
                    barony_district={data.place_of_collection.barony_district}
                    county={data.place_of_collection.county}
                    region={data.place_of_collection.region}
                    country={data.place_of_collection.country}
                    accuracy={true} // hard-coded to true because we don't have a place_of_collection accuracy field
                  />                // and if `accuracy` is falsy, `t('Uncertain Location')` is displayed in <PlaceDetails>
                )}
              </GroupedData>
            )}

            {data.publication_details && (
              <GroupedData>
                <KeyValuePair>
                  <Label>{t('Publication')}</Label>
                  <Value>{data.publication_details}</Value>
                </KeyValuePair>
              </GroupedData>
            )}
            {data.collection_event.further_notes &&
              <GroupedData>
              <KeyValuePair>
                <Label>{t('Further Notes')}</Label>
                <Value>{data.collection_event.further_notes}</Value>
              </KeyValuePair>
            </GroupedData>
            }

            {data.other_tellings && data.other_tellings.length > 0 && (
              <GroupedData>
                <KeyValuePair>
                  <Label> {t('Other Tellings')}</Label>
                  <Value>
                    {data.other_tellings.map(telling => {
                      const text = getOtherTellingText(telling);
                      const { url, new_id, document_witness } = telling;
                      return (
                        <Wrapper>
                          {url ? (
                            <ResourceLink
                              route="/item/"
                              linkText={text}
                              id={new_id}
                            />
                          ) : (
                            text
                          )}{' '}
                          {document_witness
                            .map(({ number, pages }) =>
                              [number, pages]
                                .filter(thing => !!thing)
                                .join(' : ')
                            )
                            .join(' + ')}
                        </Wrapper>
                      );
                    })}
                  </Value>
                </KeyValuePair>
              </GroupedData>
            )}

            {data.duplicate_copies && data.duplicate_copies.length > 0 && (
              <GroupedData>
                <KeyValuePair>
                  <Label>{t('Duplicate Copies')}</Label>
                  <Value>
                    {data.duplicate_copies.map((dupl: any) => {
                      return (
                        <Wrapper>
                          <ResourceLink
                            route="/item/"
                            linkText={`${dupl.new_id}: ${dupl.title ||
                              t('untitled')}`}
                            id={dupl.new_id}
                          />
                          <br />
                        </Wrapper>
                      );
                    })}
                  </Value>
                </KeyValuePair>
              </GroupedData>
            )}

            {data.reference_source && (
              <GroupedData>
                <KeyValuePair>
                  <Label>{t('Reference Source')}</Label>
                  <Value>{data.reference_source}</Value>
                </KeyValuePair>
              </GroupedData>
            )}
          </GridColumn>
        </ItemDetailsContainer>
        <LeafletMap width={'40%'} data={getPlacesForItem(data)} />
      </ItemPage>
    </PageContainer>
  );
  return (
    <Loading>
      <div className="loader"></div>
    </Loading>
  );
};
