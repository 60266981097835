// @flow

import React, {
  useCallback,
  useEffect,
  useState,
  type ComponentType
} from 'react';
import ReactGA from 'react-ga4';
import { getPageName } from '../utils/getPageName';
import { useTranslation } from 'react-i18next';
import { useAPIBase } from '../hooks/use-api-base';

const base = useAPIBase();
  
export type Props = {
  id: string,
  location?: any,
  storageKey: string,
};
  
/**
 * Status constants.
 *
 * @type {{rejected: string, notSet: string, accepted: string}}
 */
const Status = {
  accepted: 'accepted',
  notSet: 'not_set',
  rejected: 'rejected'
};


/**
 * Higher order component function used to wrap a "cookie consent" banner with Google Analytics.
 *
 * @param BannerComponent
 *
 * @returns {function(Props)}
 */
const withGoogleAnalytics = (BannerComponent: ComponentType<any>) => (props: Props) => {
  const [status, setStatus] = useState();
  const { t } = useTranslation();
  // Set the default consent state to denied and use cookieless pings
  ReactGA.gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
  });

  /**
   * Returns the Google Analytics consent value from local storage.
   *
   * @param storageKey
   *
   * @returns {string|string}
   */
  const getGoogleAnalyticsConsent = (storageKey) => (
    localStorage.getItem(storageKey) || Status.notSet
  );

  /**
   * Sets the Google Analytics consent value in local storage.
   *
   * @param storageKey
   * @param newStatus
   */
  const setGoogleAnalyticsConsent = (storageKey: string, newStatus: string) => (
    localStorage.setItem(storageKey, newStatus)
  );

  /**
   * Sets the "accepted" status and initializes analytics.
   *
   * @type {(function(): void)|*}
   */
  const onAccept = useCallback(() => {
    setStatus(Status.accepted);
    // Update the consent status to accepted
    ReactGA.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      wait_for_update: 500,
    });
    }, []);

  /**
   * Sets the "rejected" status.
   *
   * @type {(function(): void)|*}
   */
  const onDecline = useCallback(() => {
    setStatus(Status.rejected);
    // Consent status is already denied, but we can set it again to confirm the user's choice
    ReactGA.gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 500,
    });
  }, []);

  /**
   * Sets the initial status on the state from local storage.
   */
  useEffect(() => {
    setStatus(getGoogleAnalyticsConsent(props.storageKey));
  }, [props.storageKey]);

  /**
   * Sets the status on local storage when the state changes.
   */
  useEffect(() => {
    setGoogleAnalyticsConsent(props.storageKey, status || '');
  }, [status, props.storageKey]);

  /**
   * Sends the page view event if the location changes.
   */
  useEffect(() => {
    // send in either case as GA is now set up for cookieless pings if consent is not given
    if(props.location) {
      let userLanguage = localStorage.getItem("language") || "";
      getPageName(props.location.pathname, userLanguage, t).then(pageName => {
        let pageTitle = `${ t(pageName) } | ${ t('Fionn Folklore Database') }`;
        document.title = pageTitle;
        let ga_payload = {
          hitType: 'pageview',
          page_title: pageName,
          page_path: props.location.pathname + props.location.search + props.location.hash,
          page_location: props.location.pathname + props.location.search + props.location.hash,
          page_hash: props.location.hash,
          language: userLanguage
        };
        ReactGA.send(ga_payload);

        // Internal pageview tracking
        const pageviewEndpoint = `${base}/pageviews/`
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "title": pageName,
            "url": window.location.href
          })
        }
        fetch(pageviewEndpoint, requestOptions);

      });
    }
  }, [status, props.location]);

  /**
   * Initializes GoogleAnalytics when the status is set to accepted.
   */
  useEffect(() => {
    if (status === Status.accepted) {
      ReactGA.initialize(
        props.id,
      );
    }
  }, [status]);

  if (!props.id || !props.storageKey || status !== Status.notSet) {
    return null;
  }

  return (
    <BannerComponent
      {...props}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
};

export default withGoogleAnalytics;