import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../atoms/PageContainer';

export const Resources: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <div>{t("Resources placeholder text")}</div>
    </PageContainer>
  );
};
