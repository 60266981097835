import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { ResourceLink } from './ResourceLink';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';
import { Wrapper, KeyValuePair, Label, Value } from '../atoms/StyledShared';

export type PlaceDetailsProps = Partial<Place> & {
  url: string;
  id: number;
  town_village?: TownVillage | null;
  parish?: Parish | null;
  barony_district: BaronyDistrict | null;
  county: County | null;
  region: Region | null;
  country: Country | null;
  accuracy?: boolean;
};

export function PlaceDetails(props: PlaceDetailsProps)    {

    const { t } = useTranslation();
    const language = useLocale();
    
    const town_village_name = props && props.town_village ? getLocaleKeyVal(props.town_village, "town_village_name", language) : "";
    const parish_name = props && props.parish ? getLocaleKeyVal(props.parish, "parish_name", language) : "";
    const barony_district_name = props && props.barony_district ? getLocaleKeyVal(props.barony_district, "barony_district_name", language) : "";
    const county_name = props && props.county ? getLocaleKeyVal(props.county, "county_name", language) : "";
    const region_name = props && props.region ? getLocaleKeyVal(props.region, "region_name", language) : "";
    const country_name = props && props.country ? getLocaleKeyVal(props.country, "country_name", language) : "";
    const accuracy = props ? props.accuracy : true;

    return(

      <Wrapper>
        
        {
          town_village_name &&
          <KeyValuePair>
            <Label>{t('Town/Village')}</Label>
            <Value>
              {
                props && props.url
                ? <ResourceLink 
                    route="/places/"
                    linkText={town_village_name}
                    id={props.id}
                  />
                : town_village_name
              }
            </Value>
          </KeyValuePair>
        }
        
        {
          parish_name &&
          <KeyValuePair>
            <Label>{t('Parish')}</Label>
            <Value>{parish_name}</Value>
          </KeyValuePair>
        }
       
        {
           barony_district_name &&
           <KeyValuePair>
            <Label>
                {t('Barony/District/Island')}
            </Label>
            <Value>{barony_district_name}</Value>
          </KeyValuePair>
        }
        
        {
          county_name &&
          <KeyValuePair>
            <Label>
              {t('County/Historic County')}
            </Label>
            <Value>
              {county_name}
            </Value>
        </KeyValuePair>
        }
        
        {
          region_name &&
          <KeyValuePair>
            <Label>
              {t('Province/Region/State')}
            </Label>
            <Value>
              {region_name}
            </Value>
          </KeyValuePair>
        }

        {
            country_name &&
            <KeyValuePair>
            <Label>{t('Country')}</Label>
            <Value>
              {country_name}
            </Value>
            </KeyValuePair>
        }

        {
            !accuracy &&
            <KeyValuePair>
            <Label></Label>
            <Value>
            {t('Uncertain Location')}
            </Value>
            </KeyValuePair>
        }
        
      </Wrapper>
    )

}