import i18n from 'i18next';
export const UNKNOWN = 'UNKNOWN';

export function getCollectionDateInfo(
  collection_event: CollectionEvent | null | undefined
): string {
  if (collection_event === null || typeof collection_event === 'undefined')
    return UNKNOWN;

  // Uncertainty?
  const uncertainty = collection_event.date_uncertainty
    ? i18n.t(collection_event.date_uncertainty) + ' '
    : '';

  // Return the most specific information we have. If a single date, return that.
  if (collection_event.collection_date) {
    return `${uncertainty}${getDateObjInfo(
      collection_event.collection_date_obj
    )}`;
  }

  // If a date range, return that. For valid ranges, the 'day' parts will be defined in the corresponding range obj.
  if (
    collection_event.collection_range_start_date &&
    collection_event.collection_range_end_date &&
    collection_event.collection_range_start_obj &&
    collection_event.collection_range_start_obj.day &&
    collection_event.collection_range_end_obj &&
    collection_event.collection_range_end_obj.day
  ) {
    return `${uncertainty}${getDateObjInfo(
      collection_event.collection_range_start_obj
    )} - ${getDateObjInfo(collection_event.collection_range_end_obj)}`;
  }

  // Next, look in collection_date_obj
  if (collection_event.collection_date_obj) {
    const d = collection_event.collection_date_obj;
    return `${uncertainty}${getDateObjInfo(d)}`;
  }

  // Get the most specific info available from the range obj
  if (
    collection_event.collection_range_start_obj &&
    collection_event.collection_range_end_obj
  ) {
    const s = collection_event.collection_range_start_obj;
    const e = collection_event.collection_range_end_obj;
    return `${uncertainty}${getDateObjInfo(s)} - ${getDateObjInfo(e)}`;
  }

  // For ranges that start with ?
  if (collection_event.collection_range_end_obj) {
    const e = collection_event.collection_range_end_obj;
    return `${uncertainty}? - ${getDateObjInfo(e)}`;
  }

  // For ranges that end with ?
  if (collection_event.collection_range_start_obj) {
    const s = collection_event.collection_range_start_obj;
    return `${uncertainty}${getDateObjInfo(s)} - ?`;
  }

  // Makes no sense to return uncertainty with no date
  return i18n.t(UNKNOWN);
}

function getDateObjInfo(cd: CollectionDate | null): string {
  if (cd === null) return UNKNOWN;
  if (cd.year && cd.month && cd.day) {
    return `${cd.day
      .toString()
      .padStart(2, '0')}/${cd.month.toString().padStart(2, '0')}/${cd.year}`;
  }
  if (cd.year && cd.month) {
    return `${cd.month.toString().padStart(2, '0')}/${cd.year}`;
  }
  if (cd.year && cd.season) {
    return `${i18n.t(cd.season)} ${cd.year}`;
  }
  if (cd.year) {
    return `${cd.year}`;
  }
  return UNKNOWN;
}
