import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAPIBase } from '../hooks/use-api-base';
import { useFetch } from '../hooks/use-fetch-data';
import { LeafletMap } from '../components/Map';
import { PageContainer } from '../atoms/PageContainer';
import { Loading } from '../atoms/loading';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import {
  Title, TitleSpacer
} from '../atoms/StyledShared';

export const Places: React.FC = () => {
  const base = useAPIBase();
  const [results, setResults] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const data = await useFetch(`${base}/place/`);
      if (data) setResults(data);
    })();
  }, []);

  return (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/places">{t('Places')}</Link>
      </BreadCrumbs>
      <TitleSpacer>
        <Title>{t('Places')}</Title>
      </TitleSpacer>
      <div style={{ height: '800px' }}>
        {results.length > 0 ? (
          <LeafletMap data={results} height={'800px'} />
        ) : (
          <Loading>
            <div className="loader"></div>
          </Loading>
        )}
      </div>
    </PageContainer>
  );
};
