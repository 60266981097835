import { getLocaleKeyVal } from "./getLocaleKeyVal"
export function getAlternativeTitles(
    titles: AlternativeTitle[],
    language: Language
): string {
    if (language === 'en')  {
        return (
            titles.map(
                title => `${getLocaleKeyVal(title, 'alt_title', 'ga')} (${getLocaleKeyVal(title, 'alt_title', 'en')})`
            ).join(', ')
        );
    }
    else    {
        return (
            titles.map(title => getLocaleKeyVal(title, 'alt_title', language)).join(', ')
        );        
    }

}
  