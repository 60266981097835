export const storyFields = {
  summaryExists: {
    label: 'Translation or Summary Available',
    name: 'summary_exists',
    agg: 'summary_exists',
  },
  language: { label: 'Language', name: 'doc_language.language', agg: 'languages' },
  fianna: [
    { name: 'fenian', agg: 'fenian', label: 'Fianna present' },
    { name: 'not_fenian', agg: 'not_fenian', label: 'Fianna not present' },
  ],
  archive: { label: "Archive", name: 'institution.abbreviation', agg: 'institutions' },
  collection: { label: 'Collection', name: 'collection.collection_name', agg: 'collection_names' },
  location: {
    label: 'Location',
    country: {
      label: 'Country',
      en: {
        name: 'country_fields_en',
        agg: 'country_fields_en',
      },
      ga: {
        name: 'country_fields_ga',
        agg: 'country_fields_ga',
      },
      gd: {
        name: 'country_fields_gd',
        agg: 'country_fields_gd',
      },
    },
    region: {
      label: 'Province/Region/State',
      en: {
        name: 'region_fields_en',
        agg: 'region_fields_en',
      },
      ga: {
        name: 'region_fields_ga',
        agg: 'region_fields_ga',
      },
      gd: {
        name: 'region_fields_gd',
        agg: 'region_fields_gd',
      },
    },
    county: {
      label: 'County/Historic County',
      en: {
        name: 'county_fields_en',
        agg: 'county_fields_en',
      },
      ga: {
        name: 'county_fields_ga',
        agg: 'county_fields_ga',
      },
      gd: {
        name: 'county_fields_gd',
        agg: 'county_fields_gd',
      },
    },
    barony: {
      label: 'Barony/District/Island',
      en: {
        name: 'barony_district_fields_en',
        agg: 'barony_district_fields_en',
      },
      ga: {
        name: 'barony_district_fields_ga',
        agg: 'barony_district_fields_ga',
      },
      gd: {
        name: 'barony_district_fields_gd',
        agg: 'barony_district_fields_gd',
      },
    },
    parish: {
      label: 'Parish',
      en: {
        name: 'parish_fields_en',
        agg: 'parish_fields_en',
      },
      ga: {
        name: 'parish_fields_ga',
        agg: 'parish_fields_ga',
      },
      gd: {
        name: 'parish_fields_gd',
        agg: 'parish_fields_gd',
      },
    },
    town: {
      label: 'Town/Village',
      en: {
        name: 'town_village_fields_en',
        agg: 'town_village_fields_en',
      },
      ga: {
        name: 'town_village_fields_ga',
        agg: 'town_village_fields_ga',
      },
      gd: {
        name: 'town_village_fields_gd',
        agg: 'town_village_fields_gd',
      },
    },
  },
  dateRange: {
    label: 'Date Range',
    from: {
      label: 'From',
      name: 'collection_event.collection_range_start_date',
      agg: 'start_dates',
    },
    to: {
      label: 'To', 
      name: 'collection_event.collection_range_end_date', 
      agg: 'end_dates' 
    },
  },
  laytype: {
    label:'Story/Song',
    name: 'lay_type_abbreviation',
    agg_label: 'lay_type_title',
    agg: 'lay_types',
  },
  digitised: {
    label: 'Digitised',
    name: 'digitised',
    agg: 'digitised',
  },
  form: {
    label: 'Form',
    name: 'form.form.abbreviation',
    en: {
      name: 'form.form.form_en',
      agg: 'form_en',
    },
    gd: {
      name: 'form.form.form_gd',
      agg: 'form_gd',
    },
    ga: {
      name: 'form.form.form_ga',
      agg: 'form_ga',
    },
  },
};


export const personFields = {
  dateOfBirth: {
    label: 'Date of Birth',
    name: 'date_of_birth',
    agg: 'date_of_birth',
  },
  countryOfBirth: {
    label: 'Country',
    en: {
      name: 'place_of_birth.country.country_name_en',
      agg: 'country_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.country.country_name_ga',
      agg: 'country_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.country.country_name_gd',
      agg: 'country_of_birth_gd',
    },
  },
  regionOfBirth: {
    label: 'Province/Region/State',
    en: {
      name: 'place_of_birth.region.region_name_en',
      agg: 'region_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.region.region_name_ga',
      agg: 'region_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.region.region_name_gd',
      agg: 'region_of_birth_gd',
    },
  },
  countyOfBirth: {
    label: 'County/Historic County',
    en: {
      name: 'place_of_birth.county.county_name_en',
      agg: 'county_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.county.county_name_ga',
      agg: 'county_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.county.county_name_gd',
      agg: 'county_of_birth_gd',
    },
  },
  baronyOfBirth: {
    label: 'Barony/District/Island',
    en: {
      name: 'place_of_birth.barony_district.barony_district_name_en',
      agg: 'barony_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.barony_district.barony_district_name_ga',
      agg: 'barony_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.barony_district.barony_district_name_gd',
      agg: 'barony_of_birth_gd',
    },
  },
  parishOfBirth: {
    label: 'Parish',
    en: {
      name: 'place_of_birth.parish.parish_name_en',
      agg: 'parish_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.parish.parish_name_ga',
      agg: 'parish_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.parish.parish_name_gd',
      agg: 'parish_of_birth_gd',
    },
  },
  townOfBirth: {
    label: 'Town/Village',
    en: {
      name: 'place_of_birth.town_village.town_village_name_en',
      agg: 'town_of_birth_en',
    },
    ga: {
      name: 'place_of_birth.town_village.town_village_name_ga',
      agg: 'town_of_birth_ga',
    },
    gd: {
      name: 'place_of_birth.town_village.town_village_name_gd',
      agg: 'town_of_birth_gd',
    },
  },
  associatedDocuments: {
    label: 'Associated Documents',
    name: 'associated_documents',
    agg: 'associated_documents',
  },
  countryOfResidence: {
    label: 'Country',
    en: {
      name: 'personrole.place_of_residence.country.country_name_en',
      agg: 'country_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.country.country_name_ga',
      agg: 'country_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.country.country_name_gd',
      agg: 'country_of_residence_gd',
    },
  },
  regionOfResidence: {
    label: 'Province/Region/State',
    en: {
      name: 'personrole.place_of_residence.region.region_name_en',
      agg: 'region_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.region.region_name_ga',
      agg: 'region_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.region.region_name_gd',
      agg: 'region_of_residence_gd',
    },
  },
  countyOfResidence: {
    label: 'County/Historic County',
    en: {
      name: 'personrole.place_of_residence.county.county_name_en',
      agg: 'county_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.county.county_name_ga',
      agg: 'county_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.county.county_name_gd',
      agg: 'county_of_residence_gd',
    },
  },
  baronyOfResidence: {
    label: 'Barony/District/Island',
    en: {
      name: 'personrole.place_of_residence.barony_district.barony_district_name_en',
      agg: 'barony_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.barony_district.barony_district_name_ga',
      agg: 'barony_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.barony_district.barony_district_name_gd',
      agg: 'barony_of_residence_gd',
    },
  },
  parishOfResidence: {
    label: 'Parish',
    en: {
      name: 'personrole.place_of_residence.parish.parish_name_en',
      agg: 'parish_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.parish.parish_name_ga',
      agg: 'parish_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.parish.parish_name_gd',
      agg: 'parish_of_residence_gd',
    },
  },
  townOfResidence: {
    label: 'Town/Village',
    en: {
      name: 'personrole.place_of_residence.town_village.town_village_name_en',
      agg: 'town_of_residence_en',
    },
    ga: {
      name: 'personrole.place_of_residence.town_village.town_village_name_ga',
      agg: 'town_of_residence_ga',
    },
    gd: {
      name: 'personrole.place_of_residence.town_village.town_village_name_gd',
      agg: 'town_of_residence_gd',
    },
  },
  role: {
    label: 'Role',
    name: 'personrole.role',
    agg: 'role',
  },
  relationship: {
    label: 'Relationship',
    name: 'personrole.relationship',
    agg: 'relationship',
  }
};
