import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  font-size: 34px;
  font-weight: normal;
`;

export const KeyValuePair = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 16px;
  color: #616161;
  text-decoration: rgb(97, 97, 97);
  line-height: 24px;
  width: 6rem;
  margin-right: 1rem;
`;

export const Value = styled.div`
  font-size: 16px;
  color: #343a40;
  text-decoration: rgb(52, 58, 64);
  line-height: 24px;
  max-width: 24rem;
`;

export const ItemDetailsContainer = styled.div`
  padding-top: 2rem;
  max-width: 60%;
`;

export const TextLink = styled(Link)`
  display: block;
  color: black;
  cursor: pointer;
  text-decoration: underline;
`;

export const GroupedData = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const ItemPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
