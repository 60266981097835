import React, { useEffect } from 'react';
import { MediumRoundedButton } from '../atoms/Button';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const PaginationContainerNumbered = styled.div`
  margin-top: -1em;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 0.5em;
`;

const PageDropdownStyle = {
    color: "rgb(0, 123, 255)",
    border: "1px solid rgb(222, 226, 230)",
    padding: "10px",
    fontSize: "16px",
    background: "rgb(255, 255, 255)",
    borderRadius: "0px 4px 4px 0px"
}

const PageButtonStyle = {
  borderRadius: "0px 0px 0px 0px"
}

type PaginationProps = {
  page: number;
  totalPages: number;
  onSelect: (page: number) => void;
};

export function Pagination({ page, totalPages, onSelect }: PaginationProps) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (totalPages <= 1) return null;

  const handlePageSelect = (newPage: number) => {
    return () => onSelect(newPage);
  };

  const hasPreviousPage = page > 1;
  const hasNextPage = page < totalPages;

  const handlePageSelectEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(parseInt(event.target.value));
  }

  const pages = [<option key="select-page" value={page}>Select a page</option>];
  for (let i = 0; i < totalPages; i++) {
      pages.push(<option key={i+1} value={i+1}>{i+1}</option>);
  }
  return (
    <PaginationContainerNumbered>
      {hasPreviousPage ? (
        <MediumRoundedButton onClick={handlePageSelect(page - 1)}>
          {t('Previous')}
        </MediumRoundedButton>
      ) : (
        <MediumRoundedButton as="button" disabled>
          {t('Previous')}
        </MediumRoundedButton>
      )}
      {hasPreviousPage && page > 2 && (
        <MediumRoundedButton onClick={handlePageSelect(1)} style={PageButtonStyle}>
          1
        </MediumRoundedButton>
      )}
      {hasPreviousPage && page > 3 && (
        <MediumRoundedButton as="button" disabled>
          ...
        </MediumRoundedButton>
      )}
      {hasPreviousPage && (
        <MediumRoundedButton onClick={handlePageSelect(page - 1)}>
          {page >= 2 ? `${page - 1}` : ``}
        </MediumRoundedButton>
      )}
      <MediumRoundedButton
        style={{ background: '#e9e9e9', borderRadius: "0px 0px 0px 0px"}}
        onClick={handlePageSelect(page)}
      >
        {page}
      </MediumRoundedButton>
      {hasNextPage && (
        <MediumRoundedButton onClick={handlePageSelect(page + 1)}>
          {`${page + 1}`}
        </MediumRoundedButton>
      )}
      {hasNextPage && page < totalPages - 2 && (
        <MediumRoundedButton as="button" disabled>
          ...
        </MediumRoundedButton>
      )}
      {hasNextPage && page < totalPages - 1 && (
        <MediumRoundedButton onClick={handlePageSelect(totalPages)}>
          {totalPages}
        </MediumRoundedButton>
      )}
      {hasNextPage ? (
        <MediumRoundedButton onClick={handlePageSelect(page + 1)} style={PageButtonStyle}>
          {t('Next')}
        </MediumRoundedButton>
      ) : (
        <MediumRoundedButton as="button" disabled style={PageButtonStyle}>
          {t('Next')}
        </MediumRoundedButton>
      )}
      <select name="pageDropdown" onChange={handlePageSelectEvent} value="0" style={PageDropdownStyle}>
        {pages}
      </select>
    </PaginationContainerNumbered>
  );
}
