import styled from 'styled-components';

export const SearchContainer = styled.div<{ $isFocus?: boolean }>`
  font-size: 1rem;
  border-radius: 5px;
  display: flex;
  border: 1px solid #370b0b;
  background: #fff;
  line-height: 1.3em;
  width: 100%;
  max-width: 700px;
  box-shadow: none;
  &:focus {
    border-color: #333;
    outline: none;
  }
  ${props => props.$isFocus && 'background: #fff'}
`;

export const SearchContainerTransparent = styled.div<{ $isFocus?: boolean }>`
  padding: 0 0 0.5rem 0;
  font-size: 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  line-height: 1.3em;
  width: 100%;
  box-shadow: none;
  &:focus {
    border-color: #333;
    outline: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & input {
      width: unset;
    }
  }
`;

export const DropDownContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

