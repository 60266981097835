export function getWitnessData(
  witnesses: DocumentWitness[] = [],
  key: keyof DocumentWitness, 
  type: DocumentWitness['type']
): string | null{
  if (witnesses.length === 0) return null;
  let sep = key === 'number' ? ' + ' : ', ';
  const witnessData = witnesses
    .filter(witness => witness[key] && witness.type === type)
    .map(witness => witness[key]);
  // For recording notes, only need first one
  if (['notes', 'length'].includes(key) && type === 'r') {
    return witnessData[0] || '';
  }
  return witnessData.join(sep);
}