import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function usePerson(id: string) {
  const base = useAPIBase();
  return useQuery<Person>('person', async () => {
    return fetch(`${base}/person/${id}/`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data;
      });
  });
}
