import React from 'react';
import {
  Wrapper,
  WrapperBg,
  KeyValuePair,
  Label,
  Value,
  Break,
} from '../atoms/StyledShared';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { ResourceLink } from './ResourceLink';
import { PlaceDetails } from './PlaceDetails';
import { Collapsible } from './Collapsible';
import { getAllNames } from '../utils/getAllNames';
import { getLocaleName } from '../utils/getLocaleName';
import { getLocaleKeyValList } from '../utils/getLocaleKeyValList';
import { isSourceOrInterviewee, getRelTo } from '../utils/getRelTo';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';
import { getPlaceNamePriority } from '../utils/getPlaceNamePriority';

export type PersonDetailsProps = {
  id: number;
  label: string;
  canonical_name: string|null;
  name: PersonName[];
  url: string;
  gender: string;
  lifespan?: string | null;
  residence?: {
    place: Place;
    accuracy: boolean;
  }[] | null;
  native_place?: Place | null;
  place_of_birth_accuracy?: boolean;
  roles: any;
  and_or?: 'AND' | 'OR' | 'AND/OR';
  indent?: boolean;
};

export function PersonDetails(props: PersonDetailsProps) {
  const locale = useLocale();
  const { t } = useTranslation();
  const potentiallyBlankLocaleName = getLocaleName(props.canonical_name, props.name, locale);
  const localeName = potentiallyBlankLocaleName ? potentiallyBlankLocaleName : t('Unknown Person Name');
  const otherNames = getAllNames(props.name, localeName);
  const studentsClass = (
    props.roles && props.roles[0] 
    ? getLocaleKeyVal(props.roles[0], 'student_class', locale)
    : ""
  );
  const relatedDate = (
    props.roles && props.roles[0]
    ? getLocaleKeyVal(props.roles[0], 'related_date', locale)
    : ""
  );
  return (
    <WrapperBg indent={props.indent ? props.indent : false}>
      <Collapsible
        and_or={props.and_or}
        label={`${t(props.label)}: ${localeName}`}
        bold={true}
      >
        <Wrapper>
          {localeName && (
            <KeyValuePair>
              <Label>
              </Label>
              <Value>
                {props.url ? (
                    <ResourceLink
                      route="/people/"
                      linkText={localeName}
                      id={props.id}
                    />
                  ) : (
                    localeName
                )}
              </Value>
            </KeyValuePair>
          )}

          {otherNames[0] && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t('Also known as')}</Label>
                <Value>{otherNames.join(', ')}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {// Collector has no relationship info
            isSourceOrInterviewee(props.label) &&
            props.roles &&
            props.roles[0] &&
            props.roles[0].relationship && (
              <Wrapper>
                <KeyValuePair>
                  <Label>{t(getRelTo(props.label))}</Label>
                  <Value>{t(props.roles[0].relationship)}</Value>
                </KeyValuePair>
                <Break />
              </Wrapper>
            )}

          {props.roles && props.roles[0] && props.roles[0].age && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t('Age')}</Label>
                <Value>{props.roles[0].age}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {props.lifespan && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t('Lifespan')}</Label>
                <Value>{props.lifespan}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {props.gender && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t('Gender')}</Label>
                <Value>{t(props.gender)}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {props.roles &&
            props.roles[0] &&
            props.roles[0].occupation &&
            props.roles[0].occupation.length > 0 && (
              <Wrapper>
                <KeyValuePair>
                  <Label>{t('Occupation')}</Label>
                  <Value>
                    {getLocaleKeyValList(
                      props.roles[0].occupation,
                      'occupation',
                      locale
                    )}
                  </Value>
                </KeyValuePair>
                <Break />
              </Wrapper>
            )}

          {props.roles &&
            props.roles[0] &&
            props.roles[0].school_college_university &&
            props.roles[0].school_college_university.length > 0 && (
              <Wrapper>
                <KeyValuePair>
                  <Label>{t('School, College or University')}</Label>
                  <Value>
                    {getLocaleKeyValList(
                      props.roles[0].school_college_university,
                      'name',
                      locale
                    )}
                  </Value>
                </KeyValuePair>
                <Break />
              </Wrapper>
            )}

          {studentsClass && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t("Student's Class")}</Label>
                <Value>{studentsClass}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {props.roles && props.roles[0] && props.roles[0].teacher && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t("Student's Teacher")}</Label>
                <Value>{props.roles[0].teacher}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

          {props.roles && props.roles[0] && !props.roles[0].certainty && (
            <Wrapper>
              <KeyValuePair>
                <Label></Label>
                <Value>{`(${t('Identification is uncertain')})`}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}
        </Wrapper>

        {relatedDate && (
            <Wrapper>
              <KeyValuePair>
                <Label>{t('When did he/she tell this?')}</Label>
                <Value>{relatedDate}</Value>
              </KeyValuePair>
              <Break />
            </Wrapper>
          )}

        {/*
        We may know about Places even if we don't know anything about the Person
        Can have multiple Places Of Residence
        */}

        {props &&
          props.residence &&
          props.residence.length > 0 &&
          props.residence.map((obj: any) => {
            return (
              <Collapsible
                label={`${t('Place of Residence')}: ${getPlaceNamePriority(
                  obj.place, locale
                )[0]}`}
                bold={true}
              >
                <PlaceDetails
                  id={obj.place.id}
                  url={obj.place.url}
                  town_village={obj.place.town_village}
                  parish={obj.place.parish}
                  barony_district={obj.place.barony_district}
                  county={obj.place.county}
                  region={obj.place.region}
                  country={obj.place.country}
                  accuracy={obj.accuracy}
                />
              </Collapsible>
            );
          })}

        {props && props.native_place && (
          <Collapsible
            label={`${t('Native Place')}: ${getPlaceNamePriority(
              props.native_place, locale
            )[0]}`}
            bold={true}
          >
            <PlaceDetails
              id={props.native_place.id}
              url={props.native_place.url}
              town_village={props.native_place.town_village}
              parish={props.native_place.parish}
              barony_district={props.native_place.barony_district}
              county={props.native_place.county}
              region={props.native_place.region}
              country={props.native_place.country}
              accuracy={props.place_of_birth_accuracy}
            />
          </Collapsible>
        )}
      </Collapsible>
    </WrapperBg>
  );
}
