import { getLocaleKeyVal } from "./getLocaleKeyVal";

// Get array of values from an array of objects, corresponding to a key and language suffix
export function getLocaleKeyValList(
  array: any[],
  key_prefix: string,
  language: Language = 'en'
): string {
  return array.map(
    obj => getLocaleKeyVal(obj, key_prefix, language)
  ).join(', ');
}