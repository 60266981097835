import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function useItem(id: number) {
  const base = useAPIBase();
  return useQuery<Item>(['item', id], async () => {
    return fetch(`${base}/item/${id}/`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => data);
  });
}
