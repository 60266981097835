import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLocale } from '../hooks/use-locale';
import { Grid, GridColumn } from '../components/Grid';
import { DisplayCardContainerLink } from '../components/DisplayCard';

import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { useTranslation } from 'react-i18next';

import { usePerson } from '../hooks/usePerson';
import { TileDetail } from '../components/TileDetail';
import { getAllNames } from '../utils/getAllNames';
import { getPlaceNamePriority } from '../utils/getPlaceNamePriority';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';
import { getStorySongTypes } from '../utils/getStorySongTypes';
import { getCollectionDateInfo } from '../utils/getCollectionDateInfo';

import {
  Wrapper,
  KeyValuePair,
  Label30,
  Value70,
  Break,
  Title,
  TitleSpacer,
  Subheading,
  QuillFw
} from '../atoms/StyledShared';
import { getLocaleName } from '../utils/getLocaleName';
import { PlaceDetails } from '../components/PlaceDetails';
import { ResourceLink } from '../components/ResourceLink';

type RolesSectionProps = {
  roles: Role[];
  title?: string;
};

const RolesSection: React.FC<RolesSectionProps> = ({ roles = [], title }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  if (roles.length === 0) {
    return null;
  }

  return (
    <>
    {title && <Subheading>{title}</Subheading>}
      <Grid>
        {roles.map(role => {
          const title = role.associated_document.title
          const storySongTypes = getStorySongTypes(role.associated_document.laytypes, locale);
          return (
            <DisplayCardContainerLink
              to={`/item/${role.associated_document.new_id}`}
            >
              <Grid>
                <GridColumn>
                  {
                  <TileDetail label={t('Database ID')}>
                    {role.associated_document.new_id}
                  </TileDetail>
                  }
                  {title &&
                    <TileDetail label={t('Story')}>
                      {title}
                    </TileDetail>
                  }
                  {
                    storySongTypes &&
                    <TileDetail label={t('Story/Song Type(s)')}>
                    {
                      storySongTypes.map(storySongType => {
                        const linkText=`${storySongType.label}${storySongType.cf}`
                        return (
                          <>
                            <ResourceLink 
                              route="/lays/"
                              linkText={linkText}
                              id={storySongType.id}
                            /><br/>
                          </>
                        )
                      })
                    }
                    </TileDetail>
                  }
                  {
                    <TileDetail label={t('Collection Date')}>
                      {getCollectionDateInfo(role.collection_event)}
                    </TileDetail>
                  }

                  {role.place_of_residence_at_this_time && (
                    <TileDetail label={t('Place of Residence')}>
                      {[
                        ...getPlaceNamePriority(
                          role.place_of_residence_at_this_time,
                          locale
                        ),
                        getLocaleKeyVal(role.place_of_residence_at_this_time.region, 'region_name', locale),
                        getLocaleKeyVal(role.place_of_residence_at_this_time.country, 'country_name', locale)
                        ,
                      ].join(', ')}
                    </TileDetail>
                  )}
                  {role && role.related_date && (
                    <TileDetail label={t('When did he/she tell this?')}>
                      {role.related_date}
                    </TileDetail>
                  )}
                </GridColumn>
              </Grid>
            </DisplayCardContainerLink>
          );
        })}
      </Grid>
    </>
  );
};

export const Person: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data } = usePerson(params.id);
  const { t } = useTranslation();
  const language = useLocale();
  const locale = useLocale();
  
  if (!data) {
    return <PageContainer>No data</PageContainer>;
  }
  
  const potentiallyBlankLocaleName = getLocaleName(data.canonical_name, data.name, locale);
  const localeName = potentiallyBlankLocaleName ? potentiallyBlankLocaleName : t('Unknown Person Name');
  const otherNames = getAllNames(data.name, localeName);
  const collectors = data.roles && data.roles.filter(role => /Collector/i.test(role.role)) 
  const interviewees = data.roles && data.roles.filter(role => /Interviewee/i.test(role.role) && !(/Source/i.test(role.role))) 
  const sources = data.roles && data.roles.filter(role => /Source/i.test(role.role)) 
  const biography = data && getLocaleKeyVal(data, 'biography', language);

  return (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/people">{t('People')}</Link>
        {'>'}
        <Link to={`/people/${params.id}`}>
          {localeName}
        </Link>
      </BreadCrumbs>
      <TitleSpacer>
        <Title>{localeName}</Title>
      </TitleSpacer>
      {data.gender && (
        <Wrapper>
          <KeyValuePair>
            <Label30>{t('Gender')}</Label30>
            <Value70>{t(data.gender)}</Value70>
          </KeyValuePair>
          <Break />
        </Wrapper>
      )}
      {data.lifespan && (
        <Wrapper>
          <KeyValuePair>
            <Label30>{t('Lifespan')}</Label30>
            <Value70>{data.lifespan}</Value70>
          </KeyValuePair>
          <Break />
        </Wrapper>
      )}
      {/* Data not present for now */}
      {data.date_of_birth && (
        <Wrapper>
          <KeyValuePair>
            <Label30>{t('Date of Birth')}</Label30>
            <Value70>{data.date_of_birth}</Value70>
          </KeyValuePair>
          <Break />
        </Wrapper>
      )}
      {/* Data not present for now */}
      {data.date_of_death && (
        <Wrapper>
          <KeyValuePair>
            <Label30>{t('Date of Death')}</Label30>
            <Value70>{data.date_of_death}</Value70>
          </KeyValuePair>
          <Break />
        </Wrapper>
      )}
      {otherNames.length > 0 && (
        <Wrapper>
          <KeyValuePair>
            <Label30>{t('Also known as')}</Label30>
            <Value70>{otherNames.join(', ')}</Value70>
          </KeyValuePair>
          <Break />
        </Wrapper>
      )}
      
      {data && data.place_of_birth && (
        <>
          <Subheading>{t('Native place')}</Subheading>
          <PlaceDetails
            id={data.place_of_birth.id}
            url={data.place_of_birth.url}
            town_village={data.place_of_birth.town_village}
            parish={data.place_of_birth.parish}
            barony_district={data.place_of_birth.barony_district}
            county={data.place_of_birth.county}
            region={data.place_of_birth.region}
            country={data.place_of_birth.country}
            accuracy={data.place_of_birth_accuracy}
          />
        </>
      )}
      {
        biography &&
        <QuillFw dangerouslySetInnerHTML={{__html: biography}} />
      }
      <br/>
      {interviewees && (
        <RolesSection
          title={t('As an interviewee')}
          roles={interviewees}
        />
      )}
      {collectors && (
        <RolesSection
          title={t('As a collector')}
          roles={collectors}
        />
      )}
      {sources && (
        <RolesSection
          title={t("As an interviewee's source")}
          roles={sources}
        />
      )}
    </PageContainer>
  );
};
