import { getStoryTypesForLanguage } from './getStoryTypesForLanguage';
import i18n from 'i18next';
export function getStorySongTypes(
  laytypes: LayTypeDoc[] = [],
  locale: Language
): {id: string, label: string, cf: string}[]  | null{
  if (laytypes.length === 0) return null;
  return(
    laytypes
    .map((laytype: LayTypeDoc) => {
      const uncertain = laytype.uncertainty ? ' ' + i18n.t('(uncertain)') : '';
      const cf = laytype.cf ? ' (cf.)' : '';
      return ( 
        {
          id: laytype.lay_type && laytype.lay_type.fenian_tale_type ? laytype.lay_type.fenian_tale_type.toLowerCase() : "",
          label: laytype.lay_type && laytype.lay_type.fenian_tale_type ? getStoryTypesForLanguage(laytype.lay_type, locale) : "",
          cf: `${cf}${uncertain}`
        }
      )
    })
  )
}